import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(private readonly _httpClient: HttpClient) {}

  getUserSettings<T>(key: string): Observable<T> {
    const url = `/api/MenuManagerService/Settings/User/${key}`;
    return this._httpClient.get<T>(url);
  }

  updateOrCreateUserSettings<T>(key: string, value: T): Observable<T> {
    const url = `/api/MenuManagerService/Settings/User/${key}`;
    return this._httpClient.post<T>(url, value);
  }
}
