import { NotificationDto } from './../../domain/models/notification-dto';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'mmu-notification-center-view',
  templateUrl: './notification-center-view.component.html',
  styleUrls: ['./notification-center-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCenterViewComponent implements OnInit {
  @Input() notificationList: NotificationDto[] = [];

  stopAnimationFlag!: boolean;

  ngOnInit(): void {
    this.stopAnimationFlag = false;
  }

  getUnreadCount(): number {
    if (this.notificationList.length > 0) {
      const unread = this.notificationList.filter((n) => n.isUnread).length;
      return unread;
    }
    return 0;
  }

  getAnimateClass(): string {
    if (this.stopAnimationFlag) return '';
    let unreadPrio = 0;
    if (this.notificationList.length > 0) {
      unreadPrio = this.notificationList.filter(
        (n) => n.isUnread && n.priority === 0
      ).length;
    }
    return unreadPrio > 0 ? 'animate' : '';
  }
}
