<div class="kitchen-selection-page">
  <div class="kitchen-selection flex flex-column flex-column-gap">
    <div class="concern">
      <img src="assets/logos/tg_quer_2D_cmyk.svg" alt="" />
    </div>
    <div class="title flex flex-row flex-row-gap">
      <div class="product">
        MenüManager
        <span *ngIf="environment">
          {{ environment.name }}
        </span>
      </div>
    </div>
    <div>
      <h3>KUNDENAUSWAHL</h3>
    </div>
    <div>
      <p-listbox
        [options]="kitchens"
        [(ngModel)]="selectedKitchen"
        [listStyle]="{ 'max-height': '250px' }"
        optionLabel="name"
      >
        <p-header> Bitte wählen Sie einen Kunden aus </p-header>
        <ng-template let-kitchen let-i="index" pTemplate="item">
          <div>
            <div class="font-bold">
              <span>{{ kitchen.name }}</span>
              <span
                id="long"
                class="kitchen-long-term"
                *ngIf="kitchen?.longName"
                >{{ kitchen.longName }}</span
              >
              <span *ngIf="kitchen.current">(aktuell)</span>
            </div>
            <div>
              <span
                *ngFor="
                  let user of kitchen.users;
                  let index = index;
                  trackBy: trackByIndex
                "
                >{{
                  index < kitchen.users.length - 1
                    ? user.name + ",&nbsp;"
                    : user.name
                }}</span
              >
            </div>
          </div>
        </ng-template>
      </p-listbox>
      <button
        style="margin-top: 30px"
        pButton
        type="button"
        label="Kunde wählen"
        [disabled]="!selectedKitchen"
        (click)="switchToSelectedKitchen()"
      ></button>
      <div>
        {{ switchKitchenStatusErrorMessage }}
      </div>
      <!-- TODO AW 2021-04-06_08-26 add more margins -->
      <div *ngIf="loaderService.isLoading$ | async">
        <p-progressBar
          class="mm-progressbar"
          mode="indeterminate"
        ></p-progressBar>
      </div>
    </div>
  </div>
</div>
