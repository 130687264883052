<p-card *ngIf="termsAndConditions$ | async as termsAndConditions">
  <div
    class="pr-3"
    style="overflow-y: scroll"
    (scroll)="onScroll($event)"
    [style]="{ width: '100%', height: '400px' }"
  >
    <div
      id="textHeight"
      [innerHTML]="sanitizer.bypassSecurityTrustHtml(termsAndConditions)"
    ></div>
  </div>
  <div class="flex flex-row-reverse">
    <p-button
      type="submit"
      [disabled]="(notScrolledToBottom$ | async) ?? false"
      label="{{ buttonLabel }}"
      icon="fa-regular fa-check"
      class="mt-3"
      [severity]="isInOnboarding ? 'secondary' : 'primary'"
      (onClick)="acceptAGB()"
    ></p-button>
  </div>
</p-card>
