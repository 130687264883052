import { NotificationDto } from '../models/notification-dto';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private httpClient: HttpClient) {}

  getNotifications(): Observable<NotificationDto[]> {
    const url = '/api/MenuManagerService/Notifications';
    return this.httpClient.get<NotificationDto[]>(url);
  }

  setNotificationRead(number: number): Observable<NotificationDto[]> {
    const url = `/api/MenuManagerService/Notifications/${number}/SetRead`;
    return this.httpClient.post<NotificationDto[]>(url, null);
  }
}
