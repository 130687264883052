import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { KitchenDto } from '../../models/kitchen-dto';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { EnvironmentService } from '@mmu/app/core/services';
import { LoginStatusType } from '@sanalogic/ui-core/login';
import { EnvironmentDto } from '../../models';

import {
  kitchenFeature,
  KitchenState,
} from '../../data-access/reducers/kitchen.reducer';
import {
  getMyKitchens,
  switchKitchen,
} from '../../data-access/actions/kitchen.actions';
import { AuthenticationStateService } from '@mmu/app/core/services/authentication-state.service';
import { StorageService } from '@sanalogic/ui-core/storage';

@Component({
  selector: 'mmu-kitchen-selection-container',
  template: `
    <mmu-kitchen-selection-view
      [kitchens]="kitchens"
      (selectKitchen)="onKitchenSelect($event)"
      [switchKitchenStatus]="switchKitchenLoginStatus$ | async"
      [environment]="mmEnvironment"
    >
    </mmu-kitchen-selection-view>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KitchenSelectionContainerComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  kitchens!: KitchenDto[];
  mmEnvironment!: EnvironmentDto;
  switchKitchenLoginStatus$ = new BehaviorSubject<number>(0);

  kitchensSelector$ = this._kitchenStore.pipe(
    select(kitchenFeature.selectKitchens)
  );
  switchKitchenLoginStatusSelector$ = this._kitchenStore.pipe(
    select(kitchenFeature.selectSwitchKitchenLoginStatus)
  );

  private readonly _storageService = inject(StorageService).sessionStorage;
  constructor(
    private readonly _kitchenStore: Store<KitchenState>,
    private readonly _environmentService: EnvironmentService,
    private readonly _authenticationService: AuthenticationStateService //// TODO AW 2021-04-01_14-07 private profileStore: Store<ProfileState>
  ) {}

  ngOnInit(): void {
    this.subscribeToKitchenSelector();
    this.subscribeToSwitchKitchenLoginStatusSelector();

    const environment = this._environmentService.getEnvironmentFromStorage();
    if (environment) {
      this.mmEnvironment = environment;
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  onKitchenSelect(kitchen: KitchenDto): void {
    this._storageService.set('sanalogicCurrentKitchenNumber', kitchen.number); // workaround for manual order component

    this._storageService.removeAllStartingWith('fil');
    this._storageService.removeAllStartingWith('componentPage');
    this._storageService.removeAllStartingWith('articleComponentSwap');
    this._storageService.removeAllStartingWith('page');

    this._kitchenStore.dispatch(
      switchKitchen({ payload: { kitchen: kitchen } })
    );
  }

  private subscribeToKitchenSelector() {
    this.kitchensSelector$
      .pipe(takeUntil(this.destroy$))
      .subscribe((kitchens) => {
        if (kitchens) {
          this.prepareKitchens(kitchens);
        } else {
          this._kitchenStore.dispatch(
            getMyKitchens({ payload: { redirectToKitchen: true } })
          );
        }
      });
  }

  private subscribeToSwitchKitchenLoginStatusSelector() {
    this.switchKitchenLoginStatusSelector$
      .pipe(
        takeUntil(this.destroy$),
        filter((o) => o !== null)
      )
      .subscribe((loginStatus) => {
        switch (loginStatus) {
          case LoginStatusType.LoggedIn:
            //// TODO AW 2021-04-01_14-08 this.profileStore.dispatch(loadProfiles());
            break;
        }
        const status = loginStatus;
        if (status) {
          this.switchKitchenLoginStatus$.next(status);
        }
      });
  }

  private prepareKitchens(kitchens: KitchenDto[]): void {
    const deepCopy = JSON.parse(JSON.stringify(kitchens)) as KitchenDto[];
    const sortedKitchens = deepCopy.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.kitchens = sortedKitchens;

    this.kitchens = sortedKitchens.map((k) => {
      const filteredUsers = k.users?.filter(
        (u) =>
          u?.name?.toLocaleLowerCase() !==
          this._authenticationService.getFullName()?.toLowerCase()
      );

      k.users = filteredUsers?.map((f) => f);
      return k;
    });
  }
}
