import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as KitchenActions from '../actions/kitchen.actions';
import * as SettingsAction from '../actions/settings.actions';
import * as MenuPlanActions from '../../../menuplan/domain/data-access/menuplan-entity.actions';
import { MenuplanState } from '../../../menuplan/domain/data-access/menuplan-entity.reducer';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  skipWhile,
  switchMap,
  take,
} from 'rxjs/operators';
import { of } from 'rxjs';
import {
  AuthenticationV2Service,
  LoginStatusType,
} from '@sanalogic/ui-core/login';
import { Router } from '@angular/router';
import { EnvironmentService, KitchenService } from '../../services';
import { Store } from '@ngrx/store';
import { StorageService } from '@sanalogic/ui-core/storage';
import { UserActivityService } from '../../domain/infrastructure/user-activity.service';
import { UserActivityDto } from '../../models';
import { SettingsState } from '@mmu/app/settings/domain/data-access/settings.reducer';
import * as UserSettingsAction from '@mmu/app/settings/domain/data-access/settings.actions';

@Injectable()
export class KitchenEffects {
  private readonly storageService = inject(StorageService).sessionStorage;
  constructor(
    private readonly kitchenService: KitchenService,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly environmentService: EnvironmentService,
    private readonly menuPlanStore: Store<MenuplanState>,
    private readonly settingsStore: Store<SettingsState>,
    private readonly authenticationService: AuthenticationV2Service,
    private readonly _userActivityService: UserActivityService
  ) {}

  getMyKitchens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KitchenActions.getMyKitchens),
      exhaustMap((action) =>
        this.kitchenService.getMyKitchens().pipe(
          map((kitchens) => {
            if (kitchens.length > 1) {
              /* user is assigned to multiple kitchen in the tenant group */
              return KitchenActions.getMyKitchensSuccess({
                payload: { kitchens },
              });
            } else if (kitchens.length === 1) {
              /*  user is only assigned to one kitchen in the tenant group */
              this.storageService.set(
                'sanalogicCurrentKitchenNumber',
                kitchens[0].number
              );
            } else if (kitchens.length === 0) {
              /* user has no tenant group or single added kitchen */
              this.storageService.set(
                'sanalogicCurrentKitchenNumber',
                this.storageService.get('sanalogicKitchenNumber')
              );
            }
            if (action.payload.redirectToKitchen) {
              this.navigate();
            }
            return SettingsAction.loadSettings();
          }),
          catchError((error) =>
            of(
              KitchenActions.getMyKitchensNotSuccessFailed({
                payload: { error },
              })
            )
          )
        )
      )
    )
  );

  switchKitchen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KitchenActions.switchKitchen),
      exhaustMap((action) => {
        if (action.payload.kitchen.current) {
          return of(
            KitchenActions.switchKitchenSuccess({
              payload: { switchKitchenLoginStatus: LoginStatusType.LoggedIn },
            })
          );
        }

        const kitchenNumber = action.payload.kitchen.number;
        this.authenticationService.switchKitchen(kitchenNumber);

        return this.authenticationService.switchKitchenStatus$.pipe(
          skipWhile(
            // skip potential initial state from old request
            (swKitchenStatus) => swKitchenStatus !== undefined
          ),
          filter((swKitchenStatus) => swKitchenStatus !== undefined),
          take(1),
          map((swKitchenStatus) => {
            if (swKitchenStatus === LoginStatusType.LoggedIn) {
              return KitchenActions.switchKitchenSuccess({
                payload: { switchKitchenLoginStatus: swKitchenStatus },
              });
            }

            return KitchenActions.switchKitchenNotSuccess({
              payload: { switchKitchenLoginStatus: swKitchenStatus },
            });
          }),
          catchError((error) =>
            of(KitchenActions.switchKitchenFailed({ payload: { error } }))
          )
        );
      })
    )
  );

  switchKitchenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KitchenActions.switchKitchenSuccess),
      map(() => {
        this.menuPlanStore.dispatch(
          MenuPlanActions.clearMenuplanSelectedDate()
        );
        this.settingsStore.dispatch(UserSettingsAction.resetState());

        this.environmentService
          .getXsrfToken()
          .pipe(take(1))
          .subscribe(() => this.navigate());
        return KitchenActions.navigateAfterSwitchKitchen();
      })
    )
  );

  switchKitchenUserActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(KitchenActions.switchKitchen),

      switchMap(() => {
        const activity: UserActivityDto = {
          action: 'Küche wechseln',
          module: 'Login',
        };
        return this._userActivityService.addUserActivity(activity).pipe(
          map(() => {
            return KitchenActions.switchKitchenUserActivitySuccess();
          }),
          catchError(() => {
            return of(KitchenActions.switchKitchenUserActivityFailure());
          })
        );
      })
    )
  );

  private navigate(): void {
    this.router.navigateByUrl('menuplan');
  }
}
