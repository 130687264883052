import { of, skip, tap } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import * as LoginActions from '../actions/login.actions';
import {
  AuthenticationV2Service,
  AuthorizationV2Service,
  LoginData,
  LoginStatusType,
} from '@sanalogic/ui-core/login';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as KitchenActions from '../actions/kitchen.actions';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { EnvironmentService } from '../../services';
import { PasswordChangeService } from '@mmu/app/core/services/password-change.service';
import { StorageService } from '@sanalogic/ui-core/storage';

@Injectable()
export class LoginEffects {
  private readonly sessionStorageService =
    inject(StorageService).sessionStorage;
  constructor(
    private actions$: Actions,
    private authenticationService: AuthenticationV2Service,
    private authorizationService: AuthorizationV2Service,
    private router: Router,
    private environmentService: EnvironmentService,
    private passwordChangeService: PasswordChangeService
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.login),
      tap(({ payload }) => {
        this.authenticationService.login(payload.username, payload.password);
      }),
      exhaustMap(({ payload }) =>
        this.authenticationService.loginData$.pipe(
          skip(1),
          filter(
            (loginData) =>
              loginData !== undefined && loginData.loginStatus !== undefined
          ),
          map((loginData) => loginData as LoginData),
          take(1),
          map((loginData: LoginData) => {
            if (loginData.loginStatus === LoginStatusType.LoggedIn) {
              this.environmentService
                .getXsrfToken()
                .pipe(
                  withLatestFrom(this.authorizationService.hasPrivilege(600)),
                  take(1)
                )
                .subscribe(([, authorized]) => {
                  const allowedSwitchKitchen =
                    authorized && payload.redirectToKitchen;
                  if (allowedSwitchKitchen) {
                    this.router.navigateByUrl('customers').then();
                  }
                });
              return LoginActions.loginSuccess({
                payload: {
                  status: loginData.loginStatus as LoginStatusType,
                  redirectToKitchen: payload.redirectToKitchen,
                },
              });
            }

            if (loginData.loginStatus === LoginStatusType.PasswordExpired) {
              this.passwordChangeService.openPasswordChangeDialog(
                payload.password
              );
            }

            return LoginActions.loginNotSuccess({
              payload: {
                status: loginData.loginStatus as LoginStatusType,
                username: payload.username,
                nextAllowedLoginTimeUtc: loginData.nextAllowedLoginTimeUtc,
              },
            });
          }),
          catchError((error) => {
            return of(LoginActions.loginFailed({ payload: { error } }));
          })
        )
      )
    )
  );

  loginOfflineInventory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.loginOfflineInventory),
      tap(({ payload }) =>
        this.authenticationService.switchKitchen(payload.kitchenNumber)
      ),
      take(1),
      exhaustMap(() =>
        this.authenticationService.switchKitchenStatus$.pipe(
          filter((switchKitchenStatus) => switchKitchenStatus !== undefined),
          map((switchKitchenStatus) => switchKitchenStatus as LoginStatusType),
          map((switchKitchenStatus: LoginStatusType) => {
            if (switchKitchenStatus === LoginStatusType.LoggedIn) {
              return LoginActions.loginOfflineInventorySuccess({
                payload: { status: switchKitchenStatus },
              });
            }

            return LoginActions.loginOfflineInventoryNotSuccess({
              payload: {
                status: switchKitchenStatus,
              },
            });
          }),
          catchError((error) => {
            return of(LoginActions.loginFailed({ payload: { error } }));
          })
        )
      )
    )
  );

  loginOfflineInventorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.loginOfflineInventorySuccess),
      map(() => {
        this.environmentService.getXsrfToken().pipe(take(1)).subscribe();
        return LoginActions.xsrfToken();
      })
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.loginSuccess),
      map((action) => {
        return KitchenActions.getMyKitchens({
          payload: { redirectToKitchen: action.payload.redirectToKitchen },
        });
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.logout),
      tap(() => this.authenticationService.logout(['login'])),
      map(() => {
        this.sessionStorageService.clean();
        return LoginActions.logoutSuccess();
      })
    )
  );
}
