import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@sanalogic/ui-core/translate';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'mmu-root',
  template: `
    <mmu-app-toasts></mmu-app-toasts>
    <router-outlet></router-outlet>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  darkMenu = false;
  profileMode = 'inline';
  ripple = true;
  inputStyle = 'outlined';

  readonly #translateService = inject(TranslateService);
  #primeNgConfig = inject(PrimeNGConfig);
  #destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit(): void {
    this.#translateService.use({
      key: 'de',
      name: 'Deutsch',
      cultureCode: 'de',
    });

    this.#translateService
      .get('PrimeNgDefaults')
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: (translationData) => {
          this.#primeNgConfig.setTranslation(translationData);
          this.#primeNgConfig.ripple = true;
        },
      });
  }
}
