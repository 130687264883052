import { Pipe, PipeTransform } from '@angular/core';
import { PmmInventoryStateEnum } from '@mmu/app/inventory/domain';

@Pipe({
  name: 'inventoryState',
})
export class InventoryStatePipe implements PipeTransform {
  transform(value: PmmInventoryStateEnum | undefined): string {
    switch (value) {
      case PmmInventoryStateEnum.Open:
        return 'offen';
      case PmmInventoryStateEnum.Closed:
        return 'abgeschlossen';
    }
    return '';
  }
}
