import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ProfileActions from '../actions/profile.actions';
import { ProfileService } from '../../services/profile.service';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService
  ) {}

  getCollectiveOrderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileActions.loadProfiles),
      switchMap(() =>
        this.profileService.getProfileData().pipe(
          map((response) => {
            return ProfileActions.loadProfilesSuccess({ profile: response });
          }),
          catchError((error) => {
            return of(ProfileActions.loadProfilesFailure({ ...error }));
          })
        )
      )
    )
  );
}
