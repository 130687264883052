import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'mmu-offline-nav-menu',
  templateUrl: './offline-nav-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineNavMenuComponent implements OnInit {
  menuItems = new Array<MenuItem>();

  private readonly inventoryMenuItem: MenuItem = {
    label: 'Offline Inventuren',
    icon: 'fa-regular fa-clipboard-list-check',
    routerLink: ['/offline/inventories'],
  };

  ngOnInit(): void {
    this.menuItems.push(this.inventoryMenuItem);
  }
}
