<p-toast
  [baseZIndex]="baseZIndex"
  [life]="lifeTime"
  position="bottom-right"
  key="global"
></p-toast>

<p-toast [baseZIndex]="baseZIndex" position="bottom-right" key="global-link">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="grid p-fluid">
        <div class="col-12">
          <div class="flex flex-row flex-row-gap">
            <i class="fa-regular fa-check" style="font-size: 2rem"></i>
            <div>
              <div class="grid p-fluid">
                <div class="col-12">
                  <span
                    ><b>{{ message.summary }}</b></span
                  >
                </div>
                <div class="col-12">
                  <span>{{ message.detail }}</span>
                </div>
                <div class="col-12">
                  <a [attr.href]="message.data.url">{{
                    message.data.urlLabel
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<!-- ToastMessage to resolve manual added articles in inventories -->
<p-toast [baseZIndex]="baseZIndex" position="bottom-right" key="inventory">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="grid p-fluid">
        <div class="col-12">
          <div class="flex flex-row flex-row-gap">
            <i
              class="fa-regular"
              [ngClass]="message.data?.icon || 'fa-exclamation'"
              style="font-size: 2rem"
            ></i>
            <div>
              <div class="grid p-fluid">
                <div class="col-12">
                  <span
                    ><b>{{ message?.summary }}</b></span
                  >
                </div>
                <div class="col-12">
                  <span>{{ message?.detail }}</span>
                </div>
                <div class="col-12">
                  <a class="cursor-pointer" (click)="onClick()"
                    >{{ message.data?.urlLabel || "Link" }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
