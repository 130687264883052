import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { PmmManualDto } from '../../models/pmm-manual.dto';
@Component({
  selector: 'mmu-manuals-download-view',
  templateUrl: './manuals-download-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualsDownloadViewComponent {
  @Input() manuals = new Array<PmmManualDto>();
  @Input() manualsPending = false;
  @Input() activeDownloads = new Array<number>();
  @Output() downloadManual = new EventEmitter<number>();

  downloadPdf(manualNumber: number): void {
    if (this.activeDownloads.includes(manualNumber)) {
      return;
    }
    this.downloadManual.emit(manualNumber);
  }
}
