import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NotificationEffects } from './data-access/notification.effects';
import { notificationFeature } from './data-access/notification.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(notificationFeature),
    EffectsModule.forFeature([NotificationEffects]),
  ],
  exports: [],
})
export class NotificationDomainModule {}
