import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringMaxLength',
})
export class StringMaxLengthPipe implements PipeTransform {
  transform(value: string, length: number): string {
    if (value.length > length) return value.substring(0, length - 1) + '...';
    return value;
  }
}
