import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadProfiles, profileFeature, ProfileState } from '..';

@Injectable({
  providedIn: 'root',
})
export class ProfileFacade {
  readonly profile$ = this._profileStore.select(profileFeature.selectProfile);
  readonly profileIsPending$ = this._profileStore.select(
    profileFeature.selectProfileIsPending
  );

  constructor(private readonly _profileStore: Store<ProfileState>) {}

  loadProfile(): void {
    this._profileStore.dispatch(loadProfiles());
  }
}
