<div [ngStyle]="sidepanelOpen ? { width: width } : { width: '50px' }">
  <div
    class="flex flex-row flex-row-gap p-3 cursor-pointer"
    (click)="toggleSidepanel()"
  >
    <span>
      <i
        class="fa-solid fa-chevron-left mm-icon-blue"
        [ngStyle]="
          sidepanelOpen
            ? { transform: 'rotate(180deg)' }
            : { transform: 'rotate(00deg)' }
        "
        style="transition: all 0.3s"
      ></i>
    </span>
    <ng-container *ngIf="sidepanelOpen">
      <span class="font-bold">{{ titel }}</span>
    </ng-container>
  </div>
  <div class="flex flex-column flex-column-gap align-items-center">
    <ng-container *ngIf="!sidepanelOpen">
      <span
        class="font-bold writing-mode-vertical-lr cursor-pointer p-4"
        style="transform: rotate(180deg)"
        (click)="toggleSidepanel()"
      >
        {{ titel }}
      </span>
    </ng-container>
  </div>
  <ng-container *ngIf="sidepanelOpen">
    <ng-content></ng-content>
  </ng-container>
</div>
