<div
  (click)="ovelayPanel.toggle($event); this.stopAnimationFlag = true"
  class="flex flex-gap align-items-center cursor-pointer"
>
  <i
    style="font-size: 1.4em; color: #495057"
    class="fa-solid fa-bell"
    [ngClass]="getAnimateClass()"
  ></i>
  <span class="p-badge" *ngIf="getUnreadCount() > 0"
    >{{ getUnreadCount() }}
  </span>
</div>

<p-overlayPanel #ovelayPanel>
  <ng-template pTemplate>
    <div>
      <h3>Benachrichtigungen:</h3>
      <p-scrollPanel
        [style]="{ width: '500px', 'max-height': '600px', border: 'none' }"
      >
        <mmu-notification-list-view
          [ovelayPanel]="ovelayPanel"
          [notifications]="notificationList"
        ></mmu-notification-list-view>
      </p-scrollPanel>
      <a
        class="color-mm-blue"
        href=""
        routerLink="/notifications"
        (click)="ovelayPanel.hide()"
      >
        Alle Benachrichtigungen
      </a>
    </div>
  </ng-template>
</p-overlayPanel>
