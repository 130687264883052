import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'problemsTypeInfo',
})
export class ProblemsTypeInfoPipe implements PipeTransform {
  transform(value: MenuplanEntryProblem[]): string {
    let problems = '';
    value.forEach((p) => {
      switch (p.componentProblemType) {
        case 1:
          problems += 'SupplierArticleLockedInWarehouse';
          break;
        case 2:
          problems += 'SupplierArticleDeletedInWarehouse';
          break;
        case 3:
          problems += 'SupplierArticleCanceledInWarehouse';
          break;
        case 4:
          problems += 'SupplierArticleLocked';
          break;
      }
    });
    return problems;
  }
}

export interface MenuplanEntryProblem {
  componentProblemType: ProblemType;
  supplierArticleNumber: number;
}

enum ProblemType {
  SupplierArticleLockedInWarehouse = 1,
  SupplierArticleDeletedInWarehouse = 2,
  SupplierArticleCanceledInWarehouse = 3,
  SupplierArticleLocked = 4,
}
