import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import {
  LoginStatusType,
  UserLoginParametersDto,
} from '@sanalogic/ui-core/login';
import { loadEnvironment } from '../../data-access/actions/environment.action';
import { login, logout } from '../../data-access/actions/login.actions';
import { EnvironmentState } from '../../data-access/reducers/environment.reducer';
import {
  loginFeature,
  LoginState,
} from '../../data-access/reducers/login.reducer';
import { EnvironmentDto } from '@mmu/app/core/models';
import { EnvironmentService } from '../../services';

@Component({
  selector: 'mmu-login',
  template: `
    <mmu-login-view
      [pending]="pending$ | async"
      [errorMessage]="errorMessage$ | async"
      [environment]="mmEnvironment"
      (submitLogin)="onSubmit($event)"
    ></mmu-login-view>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  pending$ = new BehaviorSubject<boolean>(false);
  errorMessage$ = new BehaviorSubject<string>('');
  mmEnvironment!: EnvironmentDto | undefined;
  selectLoginState$ = this.loginStore.pipe(
    select(loginFeature.selectLoginState)
  );

  constructor(
    private loginStore: Store<LoginState>,
    private environmentStore: Store<EnvironmentState>,
    private environmentService: EnvironmentService // TODO AW 2021-04-01_11-44 //private profileStore: Store<ProfileState>
  ) {}

  ngOnInit(): void {
    this.loginStore.dispatch(logout()); // perform logout to ensure clean state
    this.environmentStore.dispatch(loadEnvironment());

    this.selectLoginState$.subscribe((loginState) => {
      switch (loginState?.status) {
        case LoginStatusType.DeviceNotAllowed:
          this.errorMessage$.next('Gerät wird nicht unterstützt.');
          break;
        case LoginStatusType.LdapUserNotInSanalogic:
          this.errorMessage$.next('Benutzername oder Passwort nicht korrekt.');
          break;
        case LoginStatusType.TooManyLoginAttempts: {
          const nextAllowedLoginTimeUtc = loginState.nextAllowedLoginTimeUtc
            ? new Date(loginState.nextAllowedLoginTimeUtc).toLocaleTimeString()
            : new Date().toLocaleTimeString();
          this.errorMessage$.next(
            'Sie haben mehrmals versucht, sich mit fehlerhaften Eingaben anzumelden. ' +
              `Der nächste Versuch ist erst um ${nextAllowedLoginTimeUtc} Uhr möglich.`
          );
          break;
        }
        case LoginStatusType.WrongCredentials:
          this.errorMessage$.next('Benutzername oder Passwort nicht korrekt.');
          break;
        case LoginStatusType.LoggedIn:
          //// TODO AW 2021-04-01_11-48 this.profileStore.dispatch(loadProfiles());
          break;
      }
    });

    this.mmEnvironment = this.environmentService.getEnvironmentFromStorage();
  }

  onSubmit(params: UserLoginParametersDto): void {
    this.loginStore.dispatch(
      login({
        payload: {
          username: params.username ?? '',
          password: params.password ?? '',
          redirectToKitchen: true,
        },
      })
    );
  }
}
