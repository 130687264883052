import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as EnvironmentActions from '../actions/environment.action';
import { EnvironmentService } from '../../services';
import { EnvironmentDto } from '../../models';
import { OpenTelemetryService } from 'ui-core-otel';

@Injectable()
export class EnvironmentEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _environmentService: EnvironmentService,
    private readonly _openTelemetryService: OpenTelemetryService
  ) {}

  laodEnvironment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(EnvironmentActions.loadEnvironment),
      exhaustMap(() =>
        this._environmentService.getEnvironment().pipe(
          map((environmentResponse: EnvironmentDto | undefined) => {
            if (environmentResponse?.enableUiTracing) {
              this._openTelemetryService.startTracing();
            } else {
              this._openTelemetryService.stopTracing();
            }
            return EnvironmentActions.loadEnvironmentSuccess({
              payload: { environment: environmentResponse },
            });
          }),
          catchError((error) =>
            of(EnvironmentActions.loadEnvironmentFail({ payload: { error } }))
          )
        )
      )
    )
  );
}
