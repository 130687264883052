import {
  Component,
  ChangeDetectionStrategy,
  Renderer2,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { AppComponent } from '../../../app.component';
import { MenuService } from '../../services/menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { select, Store } from '@ngrx/store';
import {
  idleFeature,
  IdleState,
} from '../../data-access/reducers/idle.reducer';
import { DialogService } from 'primeng/dynamicdialog';
import { NavigationService } from '../../../shared/services';
import { environment } from '@mmu/environments/environment';
import { DisplayTypeService } from '../../../displaytype.service';
import { SettingsFacadeService } from '@mmu/app/settings/domain';
import { Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';

@Component({
  selector: 'mmu-main',
  templateUrl: './main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, OnDestroy {
  mmVersion = environment.VERSION;
  rotateMenuButton = false;
  topbarMenuActive = false;
  overlayMenuActive = false;
  staticMenuDesktopInactive = false;
  staticMenuMobileActive = false;
  layoutMenuScroller?: HTMLDivElement;
  menuClick = false;
  topbarItemClick = false;
  activeTopbarItem!: unknown | null;
  menuHoverActive = false;
  configActive = false;
  configClick = false;

  hasAGBAccepted$ = this.settingsFacade.AGBSettings$;
  agbAccepted: boolean | null = null;

  menuActive!: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  selectTimeoutStatus$ = this.idleStore.pipe(
    select(idleFeature.selectTimeoutStatus)
  );
  @Input() offline = false;

  constructor(
    public renderer: Renderer2,
    private menuService: MenuService,
    public appComponent: AppComponent,
    private primengConfig: PrimeNGConfig,
    private idleStore: Store<IdleState>,
    private navigationService: NavigationService,
    public dialogService: DialogService,
    public displayTypeService: DisplayTypeService,
    public settingsFacade: SettingsFacadeService
  ) {}

  ngOnInit(): void {
    this.selectTimeoutStatus$.subscribe((status) => {
      if (!status && this.dialogService.dialogComponentRefMap.size !== 0) {
        this.dialogService.dialogComponentRefMap.forEach((dialog) =>
          dialog.destroy()
        );
      }
    });

    if (this.offline) {
      // In the offline inventory, we can be sure that the user has already accepted the terms and conditions.
      this.agbAccepted = true;
    } else {
      this.settingsFacade.getAGBSettings();
      this.hasAGBAccepted$
        .pipe(
          takeUntil(this.destroy$),
          map((agbSettings) => {
            if (agbSettings) {
              return agbSettings.AGBAccepted;
            } else {
              return null;
            }
          }),
          distinctUntilChanged()
        )
        .subscribe((agbAccepted) => {
          this.agbAccepted = agbAccepted ?? false;
        });
    }
    this.navigationService.subscibeToRoutingHistory();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onLayoutClick(): void {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.menuClick) {
      if (
        this.displayTypeService.isHorizontal() ||
        this.displayTypeService.isSlim()
      ) {
        this.menuService.reset();
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    this.configClick = false;
    this.topbarItemClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event: MouseEvent): void {
    this.menuClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;

    if (this.displayTypeService.layoutMode === 'overlay') {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.displayTypeService.isDesktop()) {
        if (this.displayTypeService.isTablet()) {
          this.staticMenuMobileActive = !this.staticMenuMobileActive;
        } else {
          this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        }
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }

    event.preventDefault();
  }

  onMenuClick(): void {
    this.menuClick = true;
  }

  onTopbarMenuButtonClick(event: MouseEvent): void {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event: MouseEvent, item: unknown): void {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event: MouseEvent): void {
    event.preventDefault();
  }

  onConfigClick(): void {
    this.configClick = true;
  }

  onRippleChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    this.appComponent.ripple = element.checked;
    this.primengConfig.ripple = element.checked;
  }

  hideOverlayMenu(): void {
    this.rotateMenuButton = false;
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }
}
