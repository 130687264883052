import { Injectable } from '@angular/core';
import { PmmManualDto } from '@mmu/app/core/models/pmm-manual.dto';
import { HtmlTemplateService } from '@mmu/app/core/services/html-template.service';
import { ManualService } from '@mmu/app/core/services/manual.service';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, Observable, switchMap, tap } from 'rxjs';

type pendingState = [manualNumber: number, isPending: boolean];

export interface ManualsDownloadState {
  manuals: PmmManualDto[];
  manualsPending: boolean;
  manualsDownloadPendingStates: pendingState[];
}

@Injectable()
export class ManualsDownloadStore extends ComponentStore<ManualsDownloadState> {
  readonly manuals$: Observable<PmmManualDto[]> = this.select(
    (state) => state.manuals
  );
  readonly manualsPending$: Observable<boolean> = this.select(
    (state) => state.manualsPending
  );
  readonly manualsDownloadPendings$: Observable<pendingState[]> = this.select(
    (state) => state.manualsDownloadPendingStates
  );

  constructor(
    private readonly _manualService: ManualService,
    private readonly _htmlTemplateService: HtmlTemplateService
  ) {
    super({
      manuals: [],
      manualsPending: false,
      manualsDownloadPendingStates: [],
    });
  }

  readonly getManuals = this.effect((origin$) =>
    origin$.pipe(
      tap(() => {
        this.setManualsPending(true);
      }),
      switchMap(() =>
        this._manualService.getManuals().pipe(
          tap({
            next: (response) => {
              return this.setManuals(response);
            },
            error: () => this.setManualsPending(false),
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  readonly downloadManualPdf = this.effect(
    (
      origin$: Observable<{
        manualNumber: number;
      }>
    ) =>
      origin$.pipe(
        tap((params) => {
          this.setPendingStates([params.manualNumber, true]);
        }),
        switchMap(({ manualNumber }) => {
          /* AW 2021-03-09_09-09 workaround from
            https://stackoverflow.com/questions/62107827/window-open-blank-doesnt-open-new-tab-on-ios-only
          */
          const openedWindow = window.open();
          if (openedWindow) {
            const htmlTemplate =
              this._htmlTemplateService.getLoadingAnimationHtmlPage();
            openedWindow.document.write(htmlTemplate);
          }
          return this._manualService.getManualPdf(manualNumber).pipe(
            tap({
              next: (response) => {
                const fileURL = window.URL.createObjectURL(response);
                if (openedWindow) {
                  openedWindow.location.href = fileURL;
                }
                return this.setPendingStates([manualNumber, false]);
              },
              error: () => {
                openedWindow?.close();
                return this.setPendingStates([manualNumber, false]);
              },
            }),
            catchError(() => EMPTY)
          );
        })
      )
  );

  private readonly setManuals = this.updater(
    (state: ManualsDownloadState, manuals: PmmManualDto[]) => {
      return {
        ...state,
        manuals: manuals,
      };
    }
  );

  private readonly setManualsPending = this.updater(
    (state: ManualsDownloadState, isPending: boolean) => {
      return {
        ...state,
        manualsPending: isPending,
      };
    }
  );

  private readonly setPendingStates = this.updater(
    (state: ManualsDownloadState, pendingState: pendingState) => {
      const filteredPendingStates = state.manualsDownloadPendingStates.filter(
        (s) => s[0] !== pendingState[0]
      );
      const newPendingStates = [...filteredPendingStates, pendingState];
      return {
        ...state,
        manualsDownloadPendingStates: newPendingStates,
      };
    }
  );
}
