import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthorizationGuardCheckingMode,
  isAuthenticated,
} from '@sanalogic/ui-core/login';
import { KitchenSelectionContainerComponent } from './core/features/kitchen-selection/kitchen-selection-container.component';
import { OnlineGuard } from './core/guards/online.guard';
import { UserPrivilege } from './core/models';
import { ForbiddenPageComponent } from './core/pages/forbidden-page/forbidden.page';
import { LoginPageComponent } from './core/pages/login/login.page';
import { NotFoundPageComponent } from './core/pages/not-found/not-found.page';
import { MainComponent } from './core/features/main/main.component';
import { PasswordRecoverySuccessfulComponent } from '@mmu/app/core/pages/password-recovery-sucessful/password-recovery-successful.component';
import {
  AFTER_PASSWORD_RESET_TARGET,
  RECOVERY_APPLICATION_CONTEXT,
} from '@sanalogic/ui-core/password/recovery';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    //add offline guard and redirect to an offline page
    component: LoginPageComponent,
  },
  {
    path: 'password-recovery',
    loadChildren: async () => {
      const m = await import('@sanalogic/ui-core/password/recovery');
      return m.recoveryRoutes;
    },
    providers: [
      { provide: RECOVERY_APPLICATION_CONTEXT, useValue: 'MenuManagerContext' },
      {
        provide: AFTER_PASSWORD_RESET_TARGET,
        useValue: 'password-reset-successful',
      },
    ],
  },
  {
    path: 'password-reset-successful',
    component: PasswordRecoverySuccessfulComponent,
  },
  {
    path: 'customers',
    component: KitchenSelectionContainerComponent,
    canActivate: [OnlineGuard],
    canMatch: [isAuthenticated],
    data: {
      privileges: [UserPrivilege.DisplaySwitchCustomer],
      checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
      navigateToIfNotAuthorized: 'page-forbidden',
    },
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [OnlineGuard],
    canMatch: [isAuthenticated],
    data: {
      navigateToIfNotAuthenticated: ['login'],
    },
    children: [
      {
        path: 'menuplan',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./menuplan/pages/menuplan-pages.module').then(
            (m) => m.MenuplanPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayMenuplanModule],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'profile',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'dashboard',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./dashboard/pages/dashboard-pages.module').then(
            (m) => m.DashboardPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayDashboardModule],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'notifications',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./notification/pages/notification-page.module').then(
            (m) => m.NotificationPagesModule
          ),
        data: {
          privileges: [UserPrivilege.UseApp],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'suppliers',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./supplier/pages/supplier-pages.module').then(
            (m) => m.SupplierPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplaySupplierModule],
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'articles',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./article/pages/article-pages.module').then(
            (m) => m.ArticlePagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayArticleModule],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      /**supplier-articles module doesn't exist anymore,
       * so redirect routing for the users who have
       * bookmarks to the supplier-articles
       */
      {
        path: 'supplier-articles',
        redirectTo: 'articles',
      },
      {
        path: 'settings',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./settings/pages/settings-pages.module').then(
            (m) => m.SettingsPagesModule
          ),
        data: {
          privileges: [
            UserPrivilege.DisplayNotificationSetting,
            UserPrivilege.DisplayDietSetting,
            UserPrivilege.DisplayStorageLocationSettings,
          ],
          checkingMode: AuthorizationGuardCheckingMode.hasAnyPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'components',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./component/pages/component-pages.module').then(
            (m) => m.ComponentPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayComponentModule],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'food-requirements',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./food-requirement/pages/food-requirement-pages.module').then(
            (m) => m.FoodRequirementPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayFoodRequirementModule],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'production',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./production/pages/production-pages.module').then(
            (m) => m.ProductionPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayProductionModule],
          checkingMode: AuthorizationGuardCheckingMode.hasPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'inventories',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./inventory/pages/inventory-pages.module').then(
            (m) => m.InventoryPagesModule
          ),
        data: {
          privileges: [UserPrivilege.DisplayInventoryModule],
          checkingMode: AuthorizationGuardCheckingMode.hasAnyPrivileges,
          navigateToIfNotAuthorized: 'page-forbidden',
        },
      },
      {
        path: 'information',
        canActivate: [OnlineGuard],
        canMatch: [isAuthenticated],
        loadChildren: () =>
          import('./information/information.module').then(
            (m) => m.InformationModule
          ),
      },
    ],
  },
  {
    path: 'offline',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './offline-inventory/pages/inventory-offline-pages.module'
          ).then((m) => m.InventoryOfflinePagesModule),
      },
    ],
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/pages/onboarding-pages.module').then(
        (m) => m.OnboardingPagesModule
      ),
  },
  {
    path: 'notfound',
    component: NotFoundPageComponent,
    canMatch: [isAuthenticated],
  },
  {
    path: 'notfound',
    component: LoginPageComponent,
  },
  {
    path: 'page-forbidden',
    component: ForbiddenPageComponent,
    canMatch: [isAuthenticated],
  },
  {
    path: 'page-forbidden',
    component: LoginPageComponent,
  },
  { path: '**', redirectTo: 'notfound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
