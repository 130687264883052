import { Pipe, PipeTransform } from '@angular/core';
import { PmmInventoryAssessmentTypeEnum } from '@mmu/app/inventory/domain';

@Pipe({
  name: 'inventoryAssessmentType',
})
export class InventoryAssesmentType implements PipeTransform {
  transform(value: PmmInventoryAssessmentTypeEnum | undefined): string {
    switch (value) {
      case PmmInventoryAssessmentTypeEnum.LastPurchasePriceOfSupplierArticle:
        return 'Letzter Einkaufspreis des Lieferartikels';
    }
    return '';
  }
}
