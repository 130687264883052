<div
  class="layout-wrapper"
  (click)="onLayoutClick()"
  [ngClass]="{
    'menu-layout-static': displayTypeService.layoutMode !== 'overlay',
    'menu-layout-overlay': displayTypeService.layoutMode === 'overlay',
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-slim': displayTypeService.layoutMode === 'slim',
    'menu-layout-horizontal': displayTypeService.layoutMode === 'horizontal',
    'layout-menu-static-inactive': this.staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive,
    'p-input-filled': appComponent.inputStyle === 'filled'
  }"
>
  <mmu-topbar [offline]="offline"></mmu-topbar>
  <div
    class="layout-menu-container flex flex-column justify-content-between"
    [ngClass]="{ 'layout-menu-dark': appComponent.darkMenu }"
    (click)="onMenuClick()"
  >
    <ng-container *ngIf="!offline; else profileOffline">
      <mmu-profile
        *ngIf="
          appComponent.profileMode === 'inline' &&
          !displayTypeService.isHorizontal()
        "
      ></mmu-profile>
    </ng-container>
    <div class="overflow-y-auto">
      <ng-container *ngIf="!offline; else offlineNavMenu">
        <mmu-nav-menu></mmu-nav-menu>
      </ng-container>
    </div>
    <div class="mm-version">v{{ mmVersion }}</div>
  </div>

  <div class="layout-main">
    <router-outlet></router-outlet>
  </div>

  <div class="layout-mask"></div>
  <p-dialog
    [modal]="true"
    header="Nutzungsbedingungen"
    [visible]="agbAccepted === false"
    [style]="{ width: '80%' }"
    [draggable]="false"
    [closable]="false"
  >
    <mmu-terms-and-conditions></mmu-terms-and-conditions>
  </p-dialog>
  <mmu-footer></mmu-footer>
</div>

<ng-template #profileOffline>
  <div class="profile" [ngClass]="{ 'profile-expanded': false }">
    <a>
      <picture>
        <source
          media="(min-width:1025px)"
          srcset="../../../../assets/logos/tg_quer_2D_cmyk_logo.svg"
        />
        <img class="profile-image" src="../../../../assets/icons/profile.svg" />
      </picture>
    </a>
  </div>
</ng-template>

<ng-template #offlineNavMenu>
  <mmu-offline-nav-menu></mmu-offline-nav-menu>
</ng-template>
