import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ToastMessageService } from '../../services';
import { ToastMessageEnum } from '../../services/toast-message.service';

@Component({
  selector: 'mmu-app-toasts',
  templateUrl: './app-toasts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppToastsComponent {
  baseZIndex = 999999999;
  lifeTime = 4000;

  constructor(private _toastMessageService: ToastMessageService) {}

  onClick(): void {
    this._toastMessageService.message$.next(ToastMessageEnum.FORWARD);
  }
}
