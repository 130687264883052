import { BehaviorSubject, combineLatest } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import {
  AuthenticationV2Service,
  SetPasswordStatusType,
} from '@sanalogic/ui-core/login';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ChangePasswordParameters } from '../../models';
import { PasswordChangeService } from '@mmu/app/core/services/password-change.service';

@Component({
  selector: 'mmu-password-change-container',
  template: `
    <mmu-password-change-view
      [errorMessage]="$any(error$ | async)"
      [isPending]="loaderService.isLoading$ | async"
      [isCurrentPasswordDisplayed]="isCurrentPasswordDisplayed$ | async"
      (changePasswordEvent)="onPasswordChange($event)"
    ></mmu-password-change-view>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChangeContainerComponent implements OnInit {
  error$ = new BehaviorSubject<string>('');

  private _password!: string;
  public isCurrentPasswordDisplayed$ = new BehaviorSubject<boolean>(false);

  constructor(
    public loaderService: LoaderService,
    private _dialogConfig: DynamicDialogConfig,
    private readonly passwordChangeService: PasswordChangeService,
    private readonly authenticationService: AuthenticationV2Service
  ) {}

  ngOnInit(): void {
    this.subscribeToChangedPassword();
  }

  onPasswordChange($event: ChangePasswordParameters): void {
    if (this.isCurrentPasswordDisplayed$.value)
      this._password = $event.currentPassword;

    this.passwordChangeService.changePassword(
      this._password,
      $event.newPassword,
      !this.isCurrentPasswordDisplayed$.value
    );
  }

  private subscribeToChangedPassword(): void {
    this._password = this._dialogConfig.data.password;
    this.isCurrentPasswordDisplayed$.next(!this._password);

    combineLatest([
      this.authenticationService.passwordChangeStatus$,
      this.authenticationService.loginData$,
    ]).subscribe(([pwChangeStatus, loginData]) => {
      const nextAllowedLoginTime =
        loginData?.nextAllowedLoginTimeUtc ?? new Date();
      switch (pwChangeStatus) {
        case SetPasswordStatusType.Successful:
          this.error$.next('');
          break;
        case SetPasswordStatusType.None:
          this.error$.next('Unbekannter Fehler.');
          break;
        case SetPasswordStatusType.NotChangedEqualPassword:
          this.error$.next(
            'Dieses Passwort wurde bereits verwendet. Bitte geben Sie ein neues Passwort ein.'
          );
          break;
        case SetPasswordStatusType.PasswordTooWeak:
          this.error$.next(
            'Das Passwort entspricht nicht den Sicherheitsrichtlinien. ' +
              'Es muss mindestens 8 Zeichen lang sein und Großbuchstaben, ' +
              'Kleinbuchstaben und Sonderzeichen enthalten. ' +
              'Darüber hinaus darf der Benutzer-, Vor- oder Nachname nicht enthalten sein.'
          );
          break;
        case SetPasswordStatusType.TooManyAttempts:
          this.error$
            .next(`Sie haben mehrmals versucht, sich mit fehlerhaften Eingaben anzumelden. ' +
            'Der nächste Versuch ist erst um ${nextAllowedLoginTime.toLocaleTimeString()} Uhr möglich.`);
          break;
        case SetPasswordStatusType.WrongCredentials:
          this.error$.next('Falsche Angaben.');
          break;
      }
    });
  }
}
