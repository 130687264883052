import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'mmu-clearbutton',
  template: `
    <ng-container *ngIf="showClearButton">
      <button
        pButton
        pRipple
        type="button"
        icon="fa-regular fa-xmark"
        class="p-button-rounded p-button-danger p-button-text"
        (click)="cleanValue.emit()"
      ></button>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClearButtonComponent {
  @Input() showClearButton = false;
  @Output() cleanValue = new EventEmitter();
}
