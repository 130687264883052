<div class="password-recover-body ui-p-fluid">
  <form [formGroup]="changePasswordForm">
    <div class="ui-g">
      <ng-container *ngIf="isCurrentPasswordDisplayed">
        <div class="col-12">
          <p>Bitte geben Sie hier Ihr aktuelles Passwort ein:</p>
        </div>
        <div class="col-12">
          <input
            pInputText
            class="input-p-fluid"
            type="password"
            formControlName="currentPassword"
            placeholder="Aktuelles Passwort"
            [readonly]="isPending"
            pTooltip="Bitte geben Sie hier ihr aktuelles Passwort ein."
            [tooltipZIndex]="'9999999999'"
          />
        </div>
      </ng-container>

      <div class="col-12">
        <p>Bitte geben Sie hier Ihr neues Passwort ein:</p>
      </div>
      <div class="col-12">
        <input
          type="password"
          pInputText
          class="input-p-fluid"
          formControlName="password"
          placeholder="Neues Passwort"
          autocomplete="new-password"
          [readonly]="isPending"
          pTooltip="Das Passwort muss mindestens 8 Zeichen lang sein und Großbuchstaben, Kleinbuchstaben und Sonderzeichen enthalten. Darüber hinaus darf der Benutzer-, Vor- oder Nachname nicht enthalten sein."
          [tooltipZIndex]="'9999999999'"
        />
      </div>
      <div class="col-12">
        <input
          type="password"
          pInputText
          class="input-p-fluid"
          formControlName="passwordRepeat"
          placeholder="Passwort wiederholen"
          autocomplete="new-password"
          [readonly]="isPending"
        />
      </div>
      <div class="col-12">
        <button
          pButton
          type="button"
          label="Passwort ändern"
          [disabled]="isPending || !changePasswordForm.valid"
          (click)="changePassword()"
        ></button>
      </div>
      <div class="col-12">
        <div *ngIf="errorMessage">
          <div class="color-mm-red">{{ errorMessage }}</div>
        </div>
      </div>
    </div>
  </form>
</div>
