import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import {
  notificationFeature,
  NotificationState,
} from '../data-access/notification.reducer';
import * as actions from '../data-access/notification.actions';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade {
  readonly notifications$ = this._notificationStore.select(
    notificationFeature.selectNotificationList
  );
  readonly notificationsPendingState$ = this._notificationStore.select(
    notificationFeature.selectNotificationsPendingState
  );

  constructor(private readonly _notificationStore: Store<NotificationState>) {}

  loadNotificationList(): void {
    this._notificationStore.dispatch(actions.getNotificationList());
  }

  setNotificationRead(number: number): void {
    this._notificationStore.dispatch(
      actions.setNotificationRead({
        payload: {
          number: number,
        },
      })
    );
  }
}
