import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { SettingsFacadeService } from '@mmu/app/settings/domain';
import { AGBSettingsDto } from '@mmu/app/settings/domain/models/agb-settings.dto';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TermsAndConditionsService } from '../../domain/infrastructure/terms-and-conditions.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mmu-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsComponent {
  settingsFacade = inject(SettingsFacadeService);
  termsAndConditions$ = inject(TermsAndConditionsService).termsAndConditions$;
  sanitizer = inject(DomSanitizer);

  @Input() isInOnboarding = false;
  @Output() agbAccepted = new EventEmitter<boolean>();

  notScrolledToBottom$ = new BehaviorSubject<boolean>(true);
  textData$: Observable<string> = of('');
  buttonLabel = 'Akzeptieren';

  constructor() {
    setTimeout(() => {
      const div = document.getElementById('textHeight');
      if (div && div.offsetHeight < 400) {
        this.notScrolledToBottom$.next(false);
      }
    }, 1000);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onScroll(event: any): void {
    if (this.buttonLabel === 'Akzeptiert') {
      return;
    }
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 1
    ) {
      this.notScrolledToBottom$.next(false);
    }
  }

  acceptAGB() {
    this.buttonLabel = 'Akzeptiert';
    this.notScrolledToBottom$.next(true);
    if (!this.isInOnboarding) {
      const payload: AGBSettingsDto = {
        AGBAccepted: true,
        AGBTimestamp: new Date().toISOString(),
      };
      this.settingsFacade.updateAGBSettings(payload);
      return;
    }
    this.agbAccepted.emit();
  }
}
