<div class="grid">
  <div class="col-12">
    <br /><span
      >Der Bedarf wurde erfolgreich angefordert.
      {{ dialogParameters.message }}</span
    >
    <br /><span
      >Sollte sich kein neues Fenster im Browser öffnen, können Sie folgenden
      Link benutzen, um auf den Transgourmet-Shop zu gelangen:</span
    >
  </div>
  <div class="col-12">
    <a class="menuplan-link" [href]="dialogParameters.url" target="_blank"
      >Transgourmet-Shop</a
    >
    <ng-container *ngIf="$any(redirectTimerObservable$ | async) > 0">
      <br /><span
        >(Automatische Weiterleitung:
        {{ redirectTimerObservable$ | async }} s)</span
      >
    </ng-container>
  </div>
  <div class="col-12">
    <span
      >Bitte vergessen Sie nicht, sich im Webshop anzumelden und Ihre Bestellung
      im Warenkorb abzuschicken.</span
    >
  </div>
</div>
<div class="dialog-content-footer">
  <button
    type="button"
    pButton
    icon="fa-regular fa-check"
    (click)="closeDialog()"
    label="Ok"
  ></button>
</div>
