import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmlTemplateService {
  getLoadingAnimationHtmlPage(): string {
    const htmlDocTemplate = `
    <!DOCTYPE html> 
    <html lang="en"> 
      
    <head> 
        <meta charset="utf-8" /> 
        <meta name="viewport" content="width=device-width, initial-scale=1.0" /> 
        <meta http-equiv="X-UA-Compatible" content="ie=edge" /> 
        <title>MenüManager</title> 
        <style> 
            #loader { 
                border: 12px solid #ff5450; 
                border-radius: 50%; 
                border-top: 12px solid #e20026; 
                width: 70px; 
                height: 70px; 
                animation: spin 1s linear infinite; 
            } 
            @keyframes spin { 
                100% { 
                    transform: rotate(360deg); 
                } 
            } 
            .center { 
                position: absolute; 
                top: 0; 
                bottom: 0; 
                left: 0; 
                right: 0; 
                margin: auto; 
            } 
        </style> 
    </head> 
    <body> 
        <div id="loader" class="center"></div> 
        <script> 
            document.onreadystatechange = function() { 
                if (document.readyState !== "complete") { 
                    document.querySelector( 
                      "body").style.visibility = "hidden"; 
                    document.querySelector( 
                      "#loader").style.visibility = "visible"; 
                } else { 
                    document.querySelector( 
                      "#loader").style.display = "none"; 
                    document.querySelector( 
                      "body").style.visibility = "visible"; 
                } 
            }; 
        </script> 
    </body> 
    </html> 
    `;

    return htmlDocTemplate;
  }
}
