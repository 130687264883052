import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceTypeInfo',
})
export class PriceTypeInfoPipe implements PipeTransform {
  transform(value: number | undefined): string {
    switch (value) {
      case 1:
        return 'Sonderpreis';
      case 2:
        return 'Preislistenpreis';
      case 3:
        return 'Kundenpreis';
      default:
        return '';
    }
  }
}
