import { NotificationService } from '../infrastructure/notification.service';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}

  loadNotificationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getNotificationList),
      switchMap(() =>
        this.notificationService.getNotifications().pipe(
          map((response) => {
            return actions.getNotificationListSuccess({
              payload: { notificationList: response },
            });
          }),
          catchError((error) =>
            of(
              actions.getNotificationListFailed({
                payload: { error },
              })
            )
          )
        )
      )
    )
  );

  setNotificationRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setNotificationRead),
      switchMap((action) =>
        this.notificationService
          .setNotificationRead(action.payload.number)
          .pipe(
            map((response) =>
              actions.setNotificationReadSuccess({
                payload: { notificationList: response },
              })
            ),
            catchError((error) =>
              of(
                actions.setNotificationReadFailed({
                  payload: { error },
                })
              )
            )
          )
      )
    )
  );
}
