import { createFeature, createReducer, on } from '@ngrx/store';
import * as KitchenActions from '../actions/kitchen.actions';
import { KitchenDto } from '../../models/kitchen-dto';

export interface KitchenState {
  kitchens: KitchenDto[];
  switchKitchenLoginStatus: number | undefined;
}

export const initialState: KitchenState = {
  kitchens: [],
  switchKitchenLoginStatus: undefined,
};

export const kitchenFeature = createFeature({
  name: 'kitchen',
  reducer: createReducer(
    initialState,
    on(KitchenActions.getMyKitchens, (state: KitchenState) => ({
      ...state,
      kitchens: [],
    })),
    on(
      KitchenActions.getMyKitchensSuccess,
      (state: KitchenState, { payload }) => ({
        ...state,
        kitchens: payload.kitchens,
      })
    ),
    on(KitchenActions.getMyKitchensNotSuccess, (state: KitchenState) => ({
      ...state,
    })),
    on(KitchenActions.getMyKitchensNotSuccessFailed, (state: KitchenState) => ({
      ...state,
    })),
    on(KitchenActions.switchKitchen, (state: KitchenState) => ({
      ...state,
      switchKitchenLoginStatus: undefined,
    })),
    on(
      KitchenActions.switchKitchenSuccess,
      (state: KitchenState, { payload }) => ({
        ...state,
        switchKitchenLoginStatus: payload.switchKitchenLoginStatus,
      })
    ),
    on(
      KitchenActions.switchKitchenNotSuccess,
      (state: KitchenState, { payload }) => ({
        ...state,
        switchKitchenLoginStatus: payload.switchKitchenLoginStatus,
      })
    ),
    on(KitchenActions.switchKitchenFailed, (state: KitchenState) => ({
      ...state,
    })),
    on(KitchenActions.navigateAfterSwitchKitchen, (state: KitchenState) => ({
      ...state,
    }))
  ),
});

export const {
  selectKitchenState,
  selectKitchens,
  selectSwitchKitchenLoginStatus,
} = kitchenFeature;
