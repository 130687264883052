<div class="mm-outer-container flex justify-content-center">
  <div
    class="inner-container flex-grow-1 p-2 md:p-4 xl:p-8 surface-200 border-round"
  >
    <div class="text-xl font-bold">Passwort zurückgesetzt</div>
    <div class="mt-2 md:mt-3 xl:mt-4">
      Ihr Passwort wurde erfolgreich zurückgesetzt. Sie werden automatisch
      weitergeleitet.
    </div>
  </div>
</div>
