import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public isLoading$ = new BehaviorSubject(false);

  changeLoadingStatus(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }
}
