import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PmmManualDto } from '../models/pmm-manual.dto';

@Injectable({
  providedIn: 'root',
})
export class ManualService {
  constructor(private readonly _http: HttpClient) {}

  getManuals(): Observable<PmmManualDto[]> {
    const url = `/api/MenuManagerService/Manuals/`;
    return this._http.get<PmmManualDto[]>(url);
  }

  getManualPdf(number: number): Observable<Blob> {
    const url = `/api/MenuManagerService/Manuals/${number}`;
    const headers = new HttpHeaders().set('Accept', 'application/pdf');
    return this._http.get(url, { headers, responseType: 'blob' });
  }
}
