import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuManagerSettings } from '../models/menuManagerSettings';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private getSettingsUrl = '/api/MenuManagerService/Settings/GetSettings';

  settings$ = new BehaviorSubject<MenuManagerSettings | null>(null);

  constructor(private http: HttpClient) {}

  getSettings(): Observable<MenuManagerSettings> {
    return this.http.get<MenuManagerSettings>(this.getSettingsUrl).pipe(
      map((settings) => {
        this.settings$.next(settings);
        return settings;
      })
    );
  }
}
