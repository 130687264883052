import { NotificationDto } from './../models/notification-dto';
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const getNotificationList = createAction(
  '[Notification] Load notification'
);

export const getNotificationListSuccess = createAction(
  '[Notification] Load notification success',
  props<{ payload: { notificationList: NotificationDto[] } }>()
);

export const getNotificationListFailed = createAction(
  '[Notification] Load notification fail',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const setNotificationRead = createAction(
  '[Notification] Set notification as read',
  props<{
    payload: { number: number };
  }>()
);

export const setNotificationReadSuccess = createAction(
  '[Notification] Set notification as read success',
  props<{
    payload: { notificationList: NotificationDto[] };
  }>()
);

export const setNotificationReadFailed = createAction(
  '[Notification] Set notification as read fail',
  props<{ payload: { error: HttpErrorResponse } }>()
);
