import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[mmuInputSelection]',
})
export class InputSelectionDirective {
  @HostListener('click', ['$event.target']) onFocus(
    element: HTMLInputElement
  ): void {
    this._onFocus(element);
  }

  private _onFocus(el: HTMLInputElement): void {
    el.focus();
    el.select();
  }
}
