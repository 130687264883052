<div class="flex flex-row align-items-center">
  <i style="font-size: 26px; color: #777" class="fa-solid fa-book"></i>

  <p-inplace [active]="active">
    <span class="title" pInplaceDisplay (click)="openChangeText()"
      >{{ inputText }} <span class="fa-solid fa-pencil icon edit-icon"></span>
    </span>
    <span class="title" pInplaceContent>
      <input
        type="text"
        [(ngModel)]="changedText"
        (ngModelChange)="checkValidation($event)"
        pInputText
        style="width: 250px; text-align: left"
      />
      <span
        class="fa-solid fa-times icon edit-icon"
        (click)="closeChangeText()"
      ></span>
      <span
        class="fa-solid fa-check icon save-icon"
        (click)="saveChangeText()"
      ></span>
      <span class="validationFailed" *ngIf="(allowSave$ | async) === false"
        >Es kann nicht gespeichert werden.</span
      >
    </span>
  </p-inplace>
</div>
