import { Pipe, PipeTransform } from '@angular/core';
import { InventoryItemOfflineEditDto } from '@mmu/app/offline-inventory/domain/models/inventory-item-offline-edit.dto';

//returns true if an inventory Item was edited and else returns false
@Pipe({
  name: 'inventoryItemOfflineEdited',
})
export class InventoryItemOfflineEditedPipe implements PipeTransform {
  transform(
    newItems: InventoryItemOfflineEditDto[] | null,
    oldItems: InventoryItemOfflineEditDto[] | null
  ): boolean {
    const oldItemsWithoutEditInfos = oldItems?.map((item) => {
      return {
        ...item,
        user: undefined,
        editTimestamp: undefined,
        id: undefined,
      };
    });
    const newItemsWithoutEditInfos = newItems?.map((item) => {
      return {
        ...item,
        user: undefined,
        editTimestamp: undefined,
        id: undefined,
      };
    });
    return (
      JSON.stringify(newItemsWithoutEditInfos) !==
      JSON.stringify(oldItemsWithoutEditInfos)
    );
  }
}
