import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './features/topbar/topbar.component';
import { NavMenuComponent } from './features/menu/nav-menu.component';
import { NavMenuItemComponent } from './features/menu/nav-menu-item.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './features/footer/footer.component';
import { ProfileComponent } from './features/profile/profile.component';
import { LoginPageComponent } from './pages/login/login.page';
import { NotFoundPageComponent } from './pages/not-found/not-found.page';
import { LoginComponent } from './features/login/login.component';
import { LoginViewComponent } from './features/login/login-view.component';
import { SharedModule } from '../shared/shared.module';
import { PasswordChangeViewComponent } from './features/change-password/password-change-view.component';
import { PasswordChangeContainerComponent } from './features/change-password/password-change-container.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ValidationService } from './services/validation.service';
import { StoreModule } from '@ngrx/store';
import { settingsFeature } from '../settings/domain/data-access/settings.reducer';
import { kitchenFeature } from './data-access/reducers/kitchen.reducer';
import { loginFeature } from './data-access/reducers/login.reducer';
import { idleFeature } from './data-access/reducers/idle.reducer';
import { environmentFeature } from './data-access/reducers/environment.reducer';
import { EnvironmentEffects } from './data-access/effects/environment.effects';
import { IdleEffects } from './data-access/effects/idle.effects';
import { LoginEffects } from './data-access/effects/login.effects';
import { KitchenEffects } from './data-access/effects/kitchen.effects';
import { SettingsEffects } from './data-access/effects/settings.effects';
import { EffectsModule } from '@ngrx/effects';
import { KitchenSelectionContainerComponent } from './features/kitchen-selection/kitchen-selection-container.component';
import { KitchenSelectionViewComponent } from './features/kitchen-selection/kitchen-selection-view.component';
import { NotificationPagesModule } from '../notification/pages/notification-page.module';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden.page';
import { ManualsDownloadContainerComponent } from './features/manuals-donwnload/manuals-download-container.component';
import { ManualsDownloadViewComponent } from './features/manuals-donwnload/manuals-download-view.component';
import { MainComponent } from './features/main/main.component';
import { OfflineNavMenuComponent } from './features/menu/offline-nav-menu/offline-nav-menu.component';
import { PasswordRecoverySuccessfulComponent } from './pages/password-recovery-sucessful/password-recovery-successful.component';
import { ProfileEffects, profileFeature } from '../profile';
import { TermsAndConditionsComponent } from './features/terms-and-conditions/terms-and-conditions.component';
import { settingsFeature as domainSettingsFeature } from '../settings/domain/data-access/settings.reducer';
import { SettingsEffects as DomainSettingsEffects } from '../settings/domain/data-access/settings.effects';

@NgModule({
  declarations: [
    TopbarComponent,
    NavMenuComponent,
    NavMenuItemComponent,
    FooterComponent,
    ProfileComponent,
    LoginPageComponent,
    NotFoundPageComponent,
    LoginComponent,
    LoginViewComponent,
    PasswordChangeViewComponent,
    PasswordChangeContainerComponent,
    KitchenSelectionContainerComponent,
    KitchenSelectionViewComponent,
    ForbiddenPageComponent,
    ManualsDownloadContainerComponent,
    ManualsDownloadViewComponent,
    MainComponent,
    OfflineNavMenuComponent,
    PasswordRecoverySuccessfulComponent,
    TermsAndConditionsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(settingsFeature),
    StoreModule.forFeature(kitchenFeature),
    StoreModule.forFeature(loginFeature),
    StoreModule.forFeature(idleFeature),
    StoreModule.forFeature(environmentFeature),
    StoreModule.forFeature(profileFeature),
    StoreModule.forFeature(domainSettingsFeature),
    EffectsModule.forFeature([DomainSettingsEffects]),
    EffectsModule.forFeature([SettingsEffects]),
    EffectsModule.forFeature([KitchenEffects]),
    EffectsModule.forFeature([LoginEffects]),
    EffectsModule.forFeature([IdleEffects]),
    EffectsModule.forFeature([EnvironmentEffects]),
    EffectsModule.forFeature([ProfileEffects]),
    SharedModule,
    NotificationPagesModule,
  ],
  exports: [
    TopbarComponent,
    NavMenuComponent,
    FooterComponent,
    ProfileComponent,
    LoginPageComponent,
    NotFoundPageComponent,
    ForbiddenPageComponent,
    MainComponent,
    TermsAndConditionsComponent,
  ],
  providers: [DialogService, ValidationService],
})
export class CoreModule {}
