import { combineLatest, Observable, switchMap } from 'rxjs';
import { map, take, timeout } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KitchenDto } from '../models/kitchen-dto';
import { AuthenticationV2Service } from '@sanalogic/ui-core/login';

@Injectable({
  providedIn: 'root',
})
export class KitchenService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationV2Service
  ) {}

  getMyKitchens(timeOut: number = 80000): Observable<KitchenDto[]> {
    return combineLatest([
      this.authenticationService.loginData$,
      this.authenticationService.deviceId$,
      this.authenticationService.username$,
    ]).pipe(
      take(1),
      switchMap(([loginData, deviceId, username]) => {
        const headers = new HttpHeaders({
          DeviceId: deviceId,
          Username: username,
          RefreshToken: loginData?.token ?? '',
        });

        return this.http
          .get<KitchenDto[]>('/api/MenuManagerService/Kitchens', {
            headers: headers,
          })
          .pipe(
            timeout(timeOut),
            map((kitchens) => {
              return kitchens;
            })
          );
      })
    );
  }
}
