import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { PanelModule } from 'primeng/panel';
import { CardModule } from 'primeng/card';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InplaceModule } from 'primeng/inplace';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessagesModule } from 'primeng/messages';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SpinnerModule } from 'primeng/spinner';
import { ToolbarModule } from 'primeng/toolbar';
import { MenuModule } from 'primeng/menu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TreeModule } from 'primeng/tree';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BadgeModule } from 'primeng/badge';
import {
  ActionbarComponent,
  MarkComponent,
  ClearButtonComponent,
  MenuComponent,
  SidepanelComponent,
  StepperComponent,
  WeekPickerComponent,
} from './components';
import {
  BackButtonDirective,
  NavigateButtonDirective,
  ZeroDirective,
} from './directives';
import { InplaceTextInputComponent } from './components/inplace-text-input/inplace-text-input.component';
import { StringMaxLengthPipe } from './pipes/string-max-length.pipe';
import { PriceTypeIconPipe } from './pipes/price-type-icon.pipe';
import { PriceTypeInfoPipe } from './pipes/price-type-info.pipe';
import { ProblemsTypeInfoPipe } from './pipes/problems-type-info.pipe';
import { KeywordLinkPipe } from './pipes/keyword-link.pipe';
import { ChartModule } from 'primeng/chart';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { AccordionModule } from 'primeng/accordion';
import { RippleModule } from 'primeng/ripple';
import { DividerModule } from 'primeng/divider';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { EmptyValidatorDirective } from './validators/empty-validator.directive';
import { PortionSizePipe } from './pipes/portion-size.pipe';
import { SkeletonModule } from 'primeng/skeleton';
import { PickListModule } from 'primeng/picklist';
import { MenuCardComponent } from '@mmu/app/shared/components';
import { KeyboardNavigationPreventionDirective } from '@mmu/app/shared/directives';
import { InfoDropdownComponent } from './components/info-dropdown/info-dropdown.component';
import { DialogInputfieldComponent } from './components/dialog-inputfield/dialog-inputfield.component';
import { PriceDisplayPipe } from './pipes/price-display.pipe';
import { InventoryFillTypeDescriptionPipe } from './pipes/inventory-fill-type-description.pipe';
import { SearchComponent } from './components/search/search.component';
import { InventoryStatePipe } from './pipes/inventory-state.pipe';
import { InventoryAssesmentType } from './pipes/inventory-assesment-type.pipe';
import { InventoryLogStatePipe } from './pipes/inventory-log-state.pipe';
import { InventorySelectedItemPipe } from './pipes/inventory-selected-item.pipe';
import { InputSelectionDirective } from './directives/input-selection.directive';
import { InventoryItemOfflineEditedPipe } from './pipes/inventory-item-offline-edited.pipe';
import { ColumnPipe } from './pipes/column.pipe';
import { HasPrivilegesDirective } from '@sanalogic/ui-core/login';
import { StepsModule } from 'primeng/steps';

@NgModule({
  declarations: [
    ActionbarComponent,
    StepperComponent,
    ZeroDirective,
    MenuComponent,
    WeekPickerComponent,
    ClearButtonComponent,
    BackButtonDirective,
    InplaceTextInputComponent,
    StringMaxLengthPipe,
    MarkComponent,
    PriceTypeIconPipe,
    PriceTypeInfoPipe,
    ProblemsTypeInfoPipe,
    KeywordLinkPipe,
    SidepanelComponent,
    PageHeaderComponent,
    NavigateButtonDirective,
    EmptyValidatorDirective,
    PortionSizePipe,
    MenuCardComponent,
    KeyboardNavigationPreventionDirective,
    InfoDropdownComponent,
    DialogInputfieldComponent,
    PriceDisplayPipe,
    InventoryFillTypeDescriptionPipe,
    SearchComponent,
    InventoryStatePipe,
    InventoryAssesmentType,
    InventoryLogStatePipe,
    InventorySelectedItemPipe,
    InputSelectionDirective,
    InventoryItemOfflineEditedPipe,
    ColumnPipe,
  ],
  imports: [
    CommonModule,
    ProgressBarModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    ListboxModule,
    PanelModule,
    CardModule,
    ScrollingModule,
    DragDropModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InplaceModule,
    KeyFilterModule,
    MessagesModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    SpinnerModule,
    ToolbarModule,
    MenuModule,
    InputSwitchModule,
    TreeModule,
    OverlayPanelModule,
    FieldsetModule,
    TabViewModule,
    SelectButtonModule,
    DynamicDialogModule,
    TableModule,
    CalendarModule,
    MultiSelectModule,
    InputNumberModule,
    InputTextareaModule,
    ChartModule,
    BadgeModule,
    AccordionModule,
    RippleModule,
    DividerModule,
    ConfirmPopupModule,
    SkeletonModule,
    PickListModule,
    HasPrivilegesDirective,
    StepsModule,
    SelectButtonModule,
  ],
  exports: [
    ProgressBarModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    ListboxModule,
    PanelModule,
    CardModule,
    ScrollingModule,
    DragDropModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    InplaceModule,
    KeyFilterModule,
    MessagesModule,
    ProgressSpinnerModule,
    ScrollPanelModule,
    SpinnerModule,
    ToolbarModule,
    MenuModule,
    InputSwitchModule,
    TreeModule,
    OverlayPanelModule,
    FieldsetModule,
    TabViewModule,
    SelectButtonModule,
    DynamicDialogModule,
    TableModule,
    CalendarModule,
    MultiSelectModule,
    InputNumberModule,
    InputTextareaModule,
    ActionbarComponent,
    StepperComponent,
    ZeroDirective,
    ClearButtonComponent,
    MenuComponent,
    WeekPickerComponent,
    BackButtonDirective,
    InplaceTextInputComponent,
    StringMaxLengthPipe,
    MarkComponent,
    PriceTypeIconPipe,
    PriceTypeInfoPipe,
    ProblemsTypeInfoPipe,
    KeywordLinkPipe,
    SidepanelComponent,
    ChartModule,
    BadgeModule,
    PageHeaderComponent,
    AccordionModule,
    RippleModule,
    DividerModule,
    NavigateButtonDirective,
    ConfirmPopupModule,
    EmptyValidatorDirective,
    PortionSizePipe,
    SkeletonModule,
    PickListModule,
    MenuCardComponent,
    KeyboardNavigationPreventionDirective,
    InfoDropdownComponent,
    DialogInputfieldComponent,
    PriceDisplayPipe,
    InventoryFillTypeDescriptionPipe,
    SearchComponent,
    InventoryStatePipe,
    InventoryAssesmentType,
    InventoryLogStatePipe,
    InventorySelectedItemPipe,
    InputSelectionDirective,
    InventoryItemOfflineEditedPipe,
    ColumnPipe,
    HasPrivilegesDirective,
    StepsModule,
    SelectButtonModule,
  ],
  providers: [ConfirmationService],
})
export class SharedModule {}
