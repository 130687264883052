import { createAction, props } from '@ngrx/store';

export const idleConfigure = createAction(
  '[Idle] Configure',
  props<{ payload: { idle: number; timeout: number; ping: number } }>()
);

export const idleConfigured = createAction('[Idle] Configured');

export const idleStartWatching = createAction(
  '[Idle] start watching',
  props<{ payload: { idle: number } }>()
);

export const idleStarted = createAction('[Idle] started');

export const idleStopped = createAction('[Idle] stopped');

export const idleStopWatching = createAction('[Idle] stop watching');

export const idleTimeoutReach = createAction('[Idle] timeout reached');

export const idleTimerStarted = createAction('[Idle] timer started');

export const idleTimerStopped = createAction('[Idle] timer stopped');
