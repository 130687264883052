import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { StorageService } from '@sanalogic/ui-core/storage';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';

@Component({
  selector: 'mmu-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input() placeholder!: string;
  @Input() searchTermStorageKey = '';
  @Output() clearSearchTerm = new EventEmitter();
  @Output() searchTermChange = new EventEmitter<string>();
  private readonly search$ = new BehaviorSubject<string>('');
  private readonly searchDebounceTime = 500;
  private _searchTerm = '';
  private readonly _sessionStorage = inject(StorageService).sessionStorage;

  constructor() {}

  ngOnInit(): void {
    this.search$
      .pipe(
        filter((searchterm) => searchterm !== null),
        debounceTime(this.searchDebounceTime)
      )
      .subscribe((searchterm) => this.searchTermChange.emit(searchterm));
  }

  get searchTerm(): string {
    if (this.searchTermStorageKey.length > 0 && !this._searchTerm) {
      const storedData = this._sessionStorage.get<string>(
        this.searchTermStorageKey
      );
      if (storedData) {
        this._searchTerm = storedData;
        this.onSearchTermChange(storedData);
      }
    }
    return this._searchTerm;
  }
  set searchTerm(searchTerm: string) {
    if (this.searchTermStorageKey.length > 0) {
      this._sessionStorage.set(this.searchTermStorageKey, searchTerm);
    }
    this._searchTerm = searchTerm;
  }

  onSearchTermChange(searchterm: string): void {
    this.search$.next(searchterm);
  }

  onCleanSearchTermClick(): void {
    this.searchTerm = '';
    this.clearSearchTerm.emit();
  }
}
