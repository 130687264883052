import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { KitchenDto } from '../../models/kitchen-dto';

export const getMyKitchens = createAction(
  '[Kitchen] get my kitchens',
  props<{ payload: { redirectToKitchen: boolean } }>()
);

export const getMyKitchensSuccess = createAction(
  '[Kitchen] get my kitchens success',
  props<{ payload: { kitchens: KitchenDto[] } }>()
);

export const getMyKitchensNotSuccess = createAction(
  '[Kitchen] get my kitchens not success'
);

export const getMyKitchensNotSuccessFailed = createAction(
  '[Kitchen] get my kitchens failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const switchKitchen = createAction(
  '[Kitchen] switch Kitchen',
  props<{ payload: { kitchen: KitchenDto } }>()
);
export const switchKitchenSuccess = createAction(
  '[Kitchen] switch Kitchen success',
  props<{ payload: { switchKitchenLoginStatus: number } }>()
);
export const switchKitchenNotSuccess = createAction(
  '[Kitchen] switch Kitchen not success',
  props<{ payload: { switchKitchenLoginStatus: number | undefined } }>()
);
export const switchKitchenFailed = createAction(
  '[Kitchen] switch Kitchen failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const navigateAfterSwitchKitchen = createAction(
  '[Kitchen] navigate to module'
);

export const switchKitchenUserActivity = createAction(
  '[Kitchen] user activity'
);
export const switchKitchenUserActivitySuccess = createAction(
  '[Kitchen] user activity success'
);
export const switchKitchenUserActivityFailure = createAction(
  '[Kitchen] user activity failure'
);
