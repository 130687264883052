import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ChangePasswordParameters } from '../../models/change-password-parameters';

@Component({
  selector: 'mmu-password-change-view',
  templateUrl: './password-change-view.component.html',
  styleUrls: ['./password-change-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChangeViewComponent implements OnInit {
  @Input() isPending: boolean | null = false;
  @Input() isCurrentPasswordDisplayed: boolean | null = false;
  @Input() errorMessage = '';

  @Output() changePasswordEvent = new EventEmitter<ChangePasswordParameters>();

  changePasswordForm!: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: [
        '',
        this.isCurrentPasswordDisplayed ? Validators.required : null,
      ],
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required],
    });
  }

  changePassword(): void {
    const currentPassword = this.changePasswordForm
      ?.get('currentPassword')
      ?.value.toString();
    const password = this.changePasswordForm?.get('password')?.value.toString();
    const passwordRepeat = this.changePasswordForm
      ?.get('passwordRepeat')
      ?.value.toString();

    if (password !== passwordRepeat) {
      this.errorMessage = 'Die eingegebenen Passwörter stimmen nicht überein.';
    } else {
      this.changePasswordEvent.emit({
        newPassword: password,
        currentPassword: currentPassword,
      });
    }
  }
}
