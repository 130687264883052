import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private readonly systemInt32Max = 2147483647;

  public isPositiveSystemInt(int: unknown): boolean {
    const number = Number(int);
    if (isNaN(number)) {
      return false;
    }
    if (!Number.isInteger(number)) {
      return false;
    }
    if (number >= 0 && number <= this.systemInt32Max) {
      return true;
    }
    return false;
  }
}
