import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mmu-inplace-text-input',
  templateUrl: './inplace-text-input.component.html',
  styleUrls: ['./inplace-text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InplaceTextInputComponent {
  @Input() inputText!: string;
  @Input() validation!: (value: string) => boolean;
  @Output() saveChanges = new EventEmitter();
  @Output() editMode = new EventEmitter();

  active = false;
  allowSave$ = new BehaviorSubject<boolean>(true);
  changedText = '';

  changeEditMode(flag: boolean): void {
    this.active = flag;
    this.editMode.emit(flag);
  }

  openChangeText(): void {
    this.changedText = this.inputText;
    this.changeEditMode(true);
    this.allowSave$.next(true);
  }

  closeChangeText(): void {
    this.changeEditMode(false);
  }

  saveChangeText(): void {
    if (this.allowSave$.value) {
      this.changeEditMode(false);
      this.saveChanges.emit(this.changedText);
    }
  }
  checkValidation(value: string): void {
    this.allowSave$.next(this.validation(value));
  }
}
