<div>
  <p>
    {{ dialogParameters.message }}<br />
    <strong *ngIf="dialogParameters.title === 'Konflikt'">
      Es sind nicht bestellbare Artikel in den Rezepten enthalten. Über die
      Bestellbarkeitsprüfung finden Sie heraus, um welche Artikel es sich
      handelt.</strong
    >
    <br />
    Sollte der Bedarf weiterhin nicht übergeben werden können, melden Sie sich
    <span *ngIf="dialogParameters.reference"
      >unter Angabe Ihrer Referenznummer
      <span class="color-mm-red" style="user-select: all">
        {{ dialogParameters.reference }}
      </span></span
    >
    beim MenüManager-Support.
  </p>
  <p>Telefon: <a href="tel:08001733733900">0800 1733733-900</a></p>
  <p>
    E-Mail:
    <a
      class="color-mm-red"
      href="mailto:support@menumanager.transgourmet.de?subject={{
        dialogParameters.reference
      }}"
    >
      support&#64;menumanager.transgourmet.de</a
    >
  </p>
</div>
<div class="dialog-content-footer">
  <button
    type="button"
    pButton
    icon="fa-regular fa-check"
    (click)="closeDialog()"
    label="Ok"
  ></button>
</div>
