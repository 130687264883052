import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ManualsDownloadStore } from './data-access/manuals-download.store';

@Component({
  selector: 'mmu-manuals-download-container',
  template: `<mmu-manuals-download-view
    [manuals]="(manuals$ | async) ?? []"
    [manualsPending]="(manualsPending$ | async) ?? false"
    [activeDownloads]="(activeDownloads$ | async) ?? []"
    (downloadManual)="downloadManual($event)"
  ></mmu-manuals-download-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ManualsDownloadStore],
})
export class ManualsDownloadContainerComponent implements OnInit {
  readonly manuals$ = this._store.manuals$;
  readonly manualsPending$ = this._store.manualsPending$;
  private readonly manualsDownloadPendingsSubscription =
    this._store.manualsDownloadPendings$.subscribe((d) => {
      this.activeDownloads$.next([
        ...d.filter((d) => d[1] === true).map((d) => d[0]),
      ]);
    });

  activeDownloads$ = new BehaviorSubject(new Array<number>());

  constructor(private readonly _store: ManualsDownloadStore) {}

  ngOnInit(): void {
    this._store.getManuals();
  }

  downloadManual(number: number) {
    this._store.downloadManualPdf({ manualNumber: number });
  }
}
