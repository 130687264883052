import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { ProfileFacade } from '../../domain/application/profile.facade';
import { SettingsFacadeService } from '@mmu/app/settings/domain';
import { AuthenticationStateService } from '@mmu/app/core/services/authentication-state.service';

@Component({
  selector: 'mmu-profile',
  templateUrl: './profile.page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  readonly profile$ = this._profileFacade.profile$.pipe(
    tap((profile) => {
      this.clientTime$.next(new Date().toUTCString());
      if (profile) {
        this.serverTime$.next(new Date(profile.serverUtcTime).toUTCString());
      }
    })
  );
  readonly profileIsPending$ = this._profileFacade.profileIsPending$;
  private _unsubscribeAll$ = new Subject<void>();

  hasConsentTracking = false;

  readonly userName = this._authenticationService.getFullName();
  readonly loginName = this._authenticationService.getUsername();
  readonly serverTime$ = new BehaviorSubject<string | null>(null);
  readonly clientTime$ = new BehaviorSubject<string>(new Date().toUTCString());

  constructor(
    private readonly _authenticationService: AuthenticationStateService,
    private readonly _profileFacade: ProfileFacade,
    private readonly _settingsFacade: SettingsFacadeService
  ) {}

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
  }

  ngOnInit(): void {
    this._profileFacade.loadProfile();
    this._settingsFacade.loadCoreSettings();
  }
}
