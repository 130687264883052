import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastMessageService } from '../services';

@Injectable({
  providedIn: 'root',
})
class OnlineService {
  constructor(
    private _router: Router,
    private _toastService: ToastMessageService
  ) {}

  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const networkStatus = window.navigator.onLine;
    if (networkStatus) return true;
    this._toastService.error(
      'Sie sind offline.',
      'Diese ausgewählte Seite ist offline nicht verfügbar.'
    );
    return this._router.parseUrl('offline/inventories');
  }
}

export const OnlineGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> => {
  return inject(OnlineService).canActivate(next, state);
};
