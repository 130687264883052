<div
  class="footer flex flex-row flex-gap justify-content-end align-items-center"
>
  <a
    class="item"
    href="https://www.transgourmet.de/impressum"
    rel="noopener"
    target="_blank"
    >Impressum</a
  >
  <a
    class="item"
    href="https://www.transgourmet.de/datenschutzerklaerung"
    rel="noopener"
    target="_blank"
    >Datenschutz</a
  >
  <i
    class="fa-solid fa-question-circle fa-lg color-mm-blue cursor-pointer"
    (click)="openManualsDownloadDialog()"
  ></i>
</div>
