import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserLoginParametersDto } from '@sanalogic/ui-core/login';
import { EnvironmentDto } from '../../models';
import { EnvironmentService } from '../../services';
@Component({
  selector: 'mmu-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['login-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginViewComponent implements OnInit {
  private _errorMessage = '';
  mmEnvironment: EnvironmentDto | undefined;
  @Input()
  get errorMessage(): string | null {
    return this._errorMessage;
  }
  set errorMessage(errorMessage: string | null) {
    this.cleanFormGroupValues();
    this._errorMessage = errorMessage ?? '';
  }
  @Input() pending?: boolean | null;
  @Input() environment?: EnvironmentDto;

  @Output() submitLogin = new EventEmitter<UserLoginParametersDto>();

  loginFormGroup!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private environmentService: EnvironmentService
  ) {}

  ngOnInit(): void {
    this.loginFormGroup = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.mmEnvironment = this.environmentService.getEnvironmentFromStorage();
  }

  onSubmit(): void {
    const userLoginParameter = {
      username: this.loginFormGroup
        ?.get('username')
        ?.value.toString()
        .toUpperCase(),
      password: this.loginFormGroup?.get('password')?.value,
    } as UserLoginParametersDto;
    this.submitLogin.emit(userLoginParameter);
  }

  private cleanFormGroupValues() {
    this.loginFormGroup?.get('password')?.setValue('');
  }
}
