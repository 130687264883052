import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '../services';

@Directive({
  selector: '[mmuNavigateButton]',
})
export class NavigateButtonDirective {
  @Input() url = '';
  constructor(private navigationService: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    const mappedUrl = this.url.replace('/', '');
    this.navigationService.navigateByUrl(`${mappedUrl}`);
  }
}
