/* eslint-disable @typescript-eslint/ban-types */
import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export const INPUT_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StepperComponent),
  multi: true,
};

@Component({
  selector: 'mmu-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [INPUT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  value: number | null = null;
  regexNumber = new RegExp('^[0-9]*$');

  @Input() maxLimit = Number.MAX_VALUE;
  @Input() minLimit = Number.MIN_VALUE;
  @Input() maxLength = 10;
  @Input() disabled = false;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() inputStyle: { [klass: string]: any } | null = null;
  @Input() startValue!: number;

  @Output() focusEvent = new EventEmitter();
  @Output() blurEvent = new EventEmitter();

  @ViewChild('inputField') inputField!: ElementRef;

  onModelChange!: Function;
  onModelTouched!: Function;

  // dir = direction (+1/-1)
  // with button click the sum of the input field is updated
  count(dir: number): void {
    if ((this.value === 0 || this.value === null) && dir === -1) {
      this.value = null;
    } else if (this.value === null) {
      this.value = this.startValue ?? null;
    } else {
      this.value += dir;
    }
    const currentValue = this.value;

    if (
      this.maxLength !== undefined &&
      this.value &&
      this.value?.toString()?.length > this.maxLength
    ) {
      this.value = currentValue;
    }
    if (
      this.maxLimit !== undefined &&
      this.value &&
      this.value >= this.maxLimit
    ) {
      this.value = this.maxLimit;
    }
    if (
      this.minLimit !== undefined &&
      this.value &&
      this.value <= this.minLimit
    ) {
      this.value = this.minLimit;
    }

    this.onModelChange(this.value);
  }

  onInput(): void {
    const inputFieldValue = this.inputField.nativeElement.value;
    if (this.regexNumber.test(inputFieldValue)) {
      this.value = parseInt(inputFieldValue, 10);
      if (isNaN(this.value)) {
        this.value = null;
      }
      this.onModelChange(this.value);
    }
  }

  onInputChange(input: string): void {
    this.value = parseInt(input, 10) || null;
    this.onModelChange(this.value);
  }

  onInputKeyPress(event: KeyboardEvent): void {
    if (event.which === 38) {
      this.count(1);
    }
    if (event.which === 40) {
      this.count(-1);
    }
  }

  //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  onInputBlur(event: any): void {
    if (this.value != 0) {
      this.onModelTouched();
      this.blurEvent.emit(event);
    }
  }

  //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  onInputFocus(event: any): void {
    this.inputField.nativeElement.focus();
    this.inputField.nativeElement.select();
    this.focusEvent.emit(event);
  }

  //eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  isNumber(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  writeValue(value: number): void {
    this.value = value;
    const nativeValue = value ?? '';
    if (this.inputField) {
      this.inputField.nativeElement.value = nativeValue.toString();
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }
}
