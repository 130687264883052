import { createFeature, on, createReducer } from '@ngrx/store';
import { ProfileData } from '../../models/profile-data-dto';
import * as ProfileActions from '../actions/profile.actions';

export const profileFeatureKey = 'profile';

export interface ProfileState {
  profile: ProfileData | null;
  profileIsPending: boolean;
}

export const initialState: ProfileState = {
  profile: null,
  profileIsPending: false,
};

export const profileFeature = createFeature({
  name: 'profile',
  reducer: createReducer(
    initialState,
    on(ProfileActions.loadProfiles, (state) => ({
      ...state,
      profileIsPending: true,
    })),
    on(ProfileActions.loadProfilesSuccess, (state, { profile }) => ({
      ...state,
      profile: profile,
      profileIsPending: false,
    })),
    on(ProfileActions.loadProfilesFailure, (state) => ({
      ...state,
      profileIsPending: false,
    }))
  ),
});

export const { selectProfileState, selectProfile, selectProfileIsPending } =
  profileFeature;
