<div class="profile" [ngClass]="{ 'profile-expanded': active }">
  <a>
    <!-- (click)="onClick($event)" -->
    <picture>
      <source
        media="(min-width:1025px)"
        srcset="../../../../assets/logos/tg_quer_2D_cmyk_logo.svg"
      />
      <img class="profile-image" src="../../../../assets/icons/profile.svg" />
    </picture>

    <span class="profile-name">{{ userName }}</span>
    <span class="profile-role">{{ userRole }}</span>
  </a>
</div>
<ul
  id="profile-menu"
  class="layout-menu"
  [@menu]="active ? 'visible' : 'hidden'"
>
  <li role="menuitem">
    <a
      href=""
      [attr.tabindex]="!active ? '-1' : null"
      routerLink="/profile"
      [routerLinkActive]="['active-menuitem-routerlink']"
      (click)="mainComponent.hideOverlayMenu()"
      pTooltip="Profil"
      tooltipStyleClass="mm-tooltip-slim"
    >
      <i class="fa-solid fa-user"></i>
      <span>Profil</span>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text"></div>
    </div>
  </li>
  <li role="menuitem">
    <a
      href=""
      routerLink="/notifications"
      [routerLinkActive]="['active-menuitem-routerlink']"
      (click)="mainComponent.hideOverlayMenu()"
      pTooltip="Benachrichtigungen"
      tooltipStyleClass="mm-tooltip-slim"
    >
      <i class="fa-solid fa-bell"></i>
      <span>Benachrichtigungen</span>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">Benachrichtigungen anzeigen</div>
    </div>
  </li>
  <li
    role="menuitem"
    *ngIf="$any((kitchen$ | async)?.length) > 1 && (isPrivileged$ | async)"
  >
    <a
      href=""
      routerLink="/customers"
      pTooltip="Kunde wechseln"
      tooltipStyleClass="mm-tooltip-slim"
    >
      <i class="fa-solid fa-fw fa-random"></i>
      <span>Kunde wechseln</span>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">Kunde wechseln</div>
    </div>
  </li>
  <li role="menuitem">
    <a
      (click)="mainComponent.hideOverlayMenu(); onPasswordChangeClick()"
      pTooltip="Passwort ändern"
      tooltipStyleClass="mm-tooltip-slim"
    >
      <i class="fa-solid fa-unlock-alt"></i>
      <span>Passwort ändern</span>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">Passwort ändern</div>
    </div>
  </li>
  <li role="menuitem">
    <a
      (click)="onLogoutClick()"
      pTooltip="Abmelden"
      tooltipStyleClass="mm-tooltip-slim"
    >
      <i class="fa-solid fa-right-from-bracket"></i>
      <span>Abmelden</span>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">Abmelden</div>
    </div>
  </li>
</ul>
