import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  DietAllocationDto,
  NutritionalUserSettings,
  ReportingUserSettings,
} from '../models';
import { DietKitchenMappingBaseDto } from '../models/diet-kitchen-mappingb.dto';
import { AGBSettingsDto } from '../models/agb-settings.dto';

//reset state
export const resetState = createAction('[Settings] reset');

//#region diet allocations
export const getDietAllocations = createAction(
  '[Settings] get diet allocations'
);
export const getDietAllocationsSuccess = createAction(
  '[Settings] get diet allocations success',
  props<{ payload: { dietAllocations: DietAllocationDto[] } }>()
);
export const getDietAllocationsFailed = createAction(
  '[Settings] get diet allocations failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const addDietAllocation = createAction(
  '[Settings] add diet allocation',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const addDietAllocationSuccess = createAction(
  '[Settings] add diet allocation success',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const addDietAllocationFailed = createAction(
  '[Settings] add diet allocation failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const removeDietAllocation = createAction(
  '[Settings] remove diet allocation',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const removeDietAllocationSuccess = createAction(
  '[Settings] remove diet allocation success',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const removeDietAllocationFailed = createAction(
  '[Settings] remove diet allocation failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const updateDietAllocationName = createAction(
  '[Settings] update diet allocation name',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const updateDietAllocationNameSuccess = createAction(
  '[Settings] update diet allocation name success',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const updateDietAllocationNameFailed = createAction(
  '[Settings] update diet allocation name failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const removeDietAllocationName = createAction(
  '[Settings] remove diet allocation name',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const removeDietAllocationNameSuccess = createAction(
  '[Settings] remove diet allocation name success',
  props<{ payload: { dietAllocation: DietAllocationDto } }>()
);
export const removeDietAllocationNameFailed = createAction(
  '[Settings] remove diet allocation name failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);
//#endregion

//#region diet settings
export const updateDietDisplayOrderSettings = createAction(
  '[Settings] update diet display order',
  props<{ payload: DietKitchenMappingBaseDto[] }>()
);

export const updateDietDisplayOrderSettingsSuccess = createAction(
  '[Settings] update diet display order success',
  props<{ payload: DietKitchenMappingBaseDto[] }>()
);

export const updateDietDisplayOrderSettingsError = createAction(
  '[Settings] update diet display order failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);
//#endregion

//#region report settings
export const getReportUserSettings = createAction(
  '[Settings] get report user settings'
);
export const getReportUserSettingsSuccess = createAction(
  '[Settings] get report user settings success',
  props<{ payload: { reportUserSettings: ReportingUserSettings } }>()
);
export const getReportUserSettingsFailed = createAction(
  '[Settings] get report user settings failed',
  props<{ payload: { error: string } }>()
);

export const updateReportUserSettings = createAction(
  '[Settings] update report user settings',
  props<{ payload: { reportUserSettings: ReportingUserSettings } }>()
);
export const updateReportUserSettingsSuccess = createAction(
  '[Settings] update report user settings success',
  props<{ payload: { reportUserSettings: ReportingUserSettings } }>()
);
export const updateReportUserSettingsFailed = createAction(
  '[Settings] update report user settings failed',
  props<{ payload: { error: string } }>()
);

//#region nutritional settings
export const getNutritionalUserSettings = createAction(
  '[Nutritional Settings] Get nutritional settings'
);
export const getNutritionalUserSettingsSuccess = createAction(
  '[Nutritional Settings] Get nutritional settings success',
  props<{ payload: { settings: NutritionalUserSettings } }>()
);
export const getNutritionalUserSettingsFailure = createAction(
  '[Nutritional Settings] Get nutritional settings failure'
);
export const updateNutritionalUserSettings = createAction(
  '[Nutritional Settings] Update nutritional settings',
  props<{ payload: { settings: NutritionalUserSettings } }>()
);
export const updateNutritionalUserSettingsSuccess = createAction(
  '[Nutritional Settings] Update nutritional settings success',
  props<{ payload: { settings: NutritionalUserSettings } }>()
);
export const updateNutritionalUserSettingsFailure = createAction(
  '[Nutritional Settings] Update nutritional settings failure'
);
//#end region nutritional settings

//#region agb settings
export const getAGBSettings = createAction('[Settings] get agb settings');
export const getAGBSettingsSuccess = createAction(
  '[Settings] get agb settings success',
  props<{ payload: { AGBSettings: AGBSettingsDto } }>()
);
export const getAGBSettingsFailed = createAction(
  '[Settings] get agb settings failed',
  props<{ payload: { error: string } }>()
);

export const updateAGBSettings = createAction(
  '[Settings] update agb settings',
  props<{ payload: { AGBSettings: AGBSettingsDto } }>()
);
export const updateAGBSettingsSuccess = createAction(
  '[Settings] update agb settings success',
  props<{ payload: { AGBSettings: AGBSettingsDto } }>()
);
export const updateAGBSettingsFailed = createAction(
  '[Settings] update agb settings failed',
  props<{ payload: { error: string } }>()
);
//#endregion agb settings
