import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { AppComponent } from '../../../app.component';
import { MainComponent } from '../main/main.component';
import { EnvironmentDto, UserPrivilege } from '../../models';
import { EnvironmentService } from '../../services';
import { LoaderService } from '../../services/loader.service';
import { Store, select } from '@ngrx/store';
import {
  kitchenFeature,
  KitchenState,
} from '../../data-access/reducers/kitchen.reducer';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { KitchenDto } from '../../models/kitchen-dto';
import { getMyKitchens } from '../../data-access/actions/kitchen.actions';
import { loadProfiles, profileFeature, ProfileState } from '@mmu/app/profile';
import { EnvironmentState } from '../../data-access/reducers/environment.reducer';
import { loadEnvironment } from '../../data-access/actions/environment.action';
import { DisplayType, DisplayTypeService } from '@mmu/app/displaytype.service';

@Component({
  selector: 'mmu-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['topbar.component.scss'],
  //here default so it updates the rotateMenuButton if parent Component closes sidebar
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TopbarComponent implements OnInit, OnDestroy {
  @Input() offline = false;
  mmEnvironment: EnvironmentDto | undefined;
  selectProfile$ = this.profileStore.pipe(select(profileFeature.selectProfile));
  selectedKitchens$ = this.kitchenStore.pipe(
    select(kitchenFeature.selectKitchens)
  );
  kitchenName: string | undefined;

  kitchenName$ = new Observable<string | undefined>();
  private destroy$ = new Subject();

  readonly displayTypes = DisplayType;
  constructor(
    public appComponent: AppComponent,
    public mainComponent: MainComponent,
    public loaderService: LoaderService,
    private environmentService: EnvironmentService,
    private profileStore: Store<ProfileState>,
    public kitchenStore: Store<KitchenState>,
    private readonly _environmentStore: Store<EnvironmentState>,
    public displayTypeService: DisplayTypeService
  ) {}

  readonly privileges = UserPrivilege;

  ngOnInit(): void {
    this._environmentStore.dispatch(loadEnvironment());
    if (!this.offline) {
      this.profileStore.dispatch(loadProfiles());
      this.kitchenStore.dispatch(
        getMyKitchens({ payload: { redirectToKitchen: false } })
      );
    }

    this.mmEnvironment = this.environmentService.getEnvironmentFromStorage();

    this.kitchenName$ = combineLatest([
      this.selectProfile$,
      this.selectedKitchens$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([profile, kitchens]) => {
        return kitchens
          ?.map((k: KitchenDto) =>
            k.name === profile?.kitchenName
              ? `${profile.kitchenName} ${k.longName ? k.longName : ''}`
              : ''
          )
          .find((element) => element !== '');
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }
}
