import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProfileData } from '../models/profile-data-dto';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  private getProfileDataUrl = '/api/MenuManagerService/Profile/GetProfileData';

  public getProfileData(): Observable<ProfileData> {
    return this.http.get<ProfileData>(this.getProfileDataUrl);
  }
}
