import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from 'projects/menumanager-ui/src/environments/environment';
import { Subject } from 'rxjs';
import { AuthorizationV2Service } from '@sanalogic/ui-core/login';
import { MainComponent } from '../main/main.component';
import { logout } from '../../data-access/actions/login.actions';
import {
  kitchenFeature,
  KitchenState,
} from '../../data-access/reducers/kitchen.reducer';
import { LoginState } from '../../data-access/reducers/login.reducer';
import { DialogParameters } from '../../models';
import { DialogsService } from '../../services';
import { PasswordChangeService } from '@mmu/app/core/services/password-change.service';

@Component({
  selector: 'mmu-profile',
  templateUrl: './profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('menu', [
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition(
        'visible => hidden',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hidden => visible',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class ProfileComponent implements OnDestroy {
  version = environment.VERSION;

  userRole = 'MenüManager';
  userName = '';
  active = true;
  kitchen$ = this.kitchenStore.pipe(select(kitchenFeature.selectKitchens));
  isPrivileged$ = this.authorizationService.hasPrivilege(600);
  private destroy$ = new Subject();

  constructor(
    private readonly authorizationService: AuthorizationV2Service,
    private readonly loginStore: Store<LoginState>,
    private readonly kitchenStore: Store<KitchenState>,
    private readonly dialogService: DialogsService,
    public readonly mainComponent: MainComponent,
    private readonly passwordChangeService: PasswordChangeService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  onPasswordChangeClick(): void {
    this.passwordChangeService.openPasswordChangeDialog();
  }

  onLogoutClick(): void {
    const dialogParameter: DialogParameters = {
      title: 'Abmelden bestätigen',
      message: 'Möchten Sie sich wirklich abmelden?',
      labels: { confirm: 'Ja', negate: 'Nein' },
    };

    this.dialogService
      .showConfirm(dialogParameter)
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.loginStore.dispatch(logout());
        }
      });
  }
}
