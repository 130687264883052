import { createFeature, createReducer, on } from '@ngrx/store';
import * as IdleActions from '../actions/idle.actions';

export interface IdleState {
  idle: number;
  timeout: number;
  ping: number;
  timeoutStatus: boolean;
}

export const initialState: IdleState = {
  idle: 0,
  timeout: 0,
  ping: 0,
  timeoutStatus: false,
};

export const idleFeature = createFeature({
  name: 'idle',
  reducer: createReducer(
    initialState,
    on(IdleActions.idleConfigure, (state: IdleState, { payload }) => ({
      ...state,
      idle: payload.idle,
      ping: payload.ping,
      timeout: payload.timeout,
      timeoutStatus: true,
    })),
    on(IdleActions.idleConfigured, (state: IdleState) => ({ ...state })),
    on(IdleActions.idleStartWatching, (state: IdleState) => ({ ...state })),
    on(IdleActions.idleStarted, (state: IdleState) => ({ ...state })),
    on(IdleActions.idleStopWatching, (state: IdleState) => ({ ...state })),
    on(IdleActions.idleStopped, (state: IdleState) => ({ ...state })),
    on(IdleActions.idleTimerStarted, (state: IdleState) => ({ ...state })),
    on(IdleActions.idleTimeoutReach, (state: IdleState) => ({
      ...state,
      timeoutStatus: false,
    })),
    on(IdleActions.idleTimerStopped, (state: IdleState) => ({ ...state }))
  ),
});

export const {
  selectIdleState,
  selectIdle,
  selectTimeout,
  selectPing,
  selectTimeoutStatus,
} = idleFeature;
