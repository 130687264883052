import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NavigationService } from '../../../shared/services';

@Component({
  selector: 'mmu-not-found',
  templateUrl: './not-found.page.html',
  styleUrls: ['./not-found.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
  constructor(private _navigationService: NavigationService) {}

  previousPage(): void {
    this._navigationService.navigateBack();
  }
}
