import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  inject,
  OnDestroy,
} from '@angular/core';
import { AuthorizationV2Service } from '@sanalogic/ui-core/login';
import { UserPrivilege } from '../../models';
import { combineLatest, Subscription, tap } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'mmu-nav-menu',
  templateUrl: './nav-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent implements OnInit, OnDestroy {
  private readonly authorizationService = inject(AuthorizationV2Service);
  menuItems: MenuItem[] = [];

  private readonly dashboardMenuItem: MenuItem = {
    label: 'Dashboard',
    icon: 'fa-solid fa-chart-column',
    routerLink: ['/dashboard'],
  };
  private readonly menuPlanMenuItem: MenuItem = {
    label: 'Speiseplan / Einkauf',
    icon: 'fa-regular fa-calendar',
    routerLink: ['/menuplan'],
  };
  private readonly supplierMenuItem: MenuItem = {
    label: 'Lieferanten',
    icon: 'mm-icon mm-icon-large icon-truck',
    routerLink: ['/suppliers'],
  };
  private readonly articleMenuItem: MenuItem = {
    label: 'Artikel',
    icon: 'fa-solid fa-file',
    routerLink: ['/articles'],
  };
  private readonly componentMenuItem: MenuItem = {
    label: 'Rezepte',
    icon: 'fa-solid fa-book',
    routerLink: ['/components'],
  };
  private readonly foodRequirementMenuItem: MenuItem = {
    label: 'Speisenanforderung',
    icon: 'fa-solid fa-users',
    routerLink: ['/food-requirements'],
  };
  private readonly productionMenuItem: MenuItem = {
    label: 'Produktion',
    icon: 'mm-icon mm-icon-large icon-whisk',
    routerLink: ['/production'],
  };
  private readonly inventoryMenuItem: MenuItem = {
    label: 'Inventur',
    icon: 'fa-regular fa-clipboard-list-check',
    routerLink: ['/inventories'],
  };

  private readonly settingsMenuItem: MenuItem = {
    label: 'Einstellungen',
    icon: 'fa-solid fa-cogs',
    routerLink: ['/settings'],
  };

  private readonly informationMenuItem: MenuItem = {
    label: 'Informationen',
    icon: 'fa-solid fa-info-circle',
    routerLink: ['/information'],
  };

  private sub!: Subscription;

  ngOnInit(): void {
    this.sub = combineLatest([
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayDashboardModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayMenuplanModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplaySupplierModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayArticleModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayComponentModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayFoodRequirementModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayProductionModule
      ),
      this.authorizationService.hasPrivilege(
        UserPrivilege.DisplayInventoryModule
      ),
      this.authorizationService.hasAnyPrivileges([
        UserPrivilege.DisplayNotificationSetting,
        UserPrivilege.DisplayDietSetting,
        UserPrivilege.DisplayStorageLocationSettings,
      ]),
    ])
      .pipe(
        tap(
          ([
            displayDashboard,
            displayMenuplan,
            displaySupplier,
            displayArticle,
            displayComponent,
            displayFoodRequirement,
            displayProduction,
            displayInventory,
            displaySettings,
          ]) => {
            this.menuItems = [];
            if (displayDashboard) this.menuItems.push(this.dashboardMenuItem);
            if (displayMenuplan) this.menuItems.push(this.menuPlanMenuItem);
            if (displaySupplier) this.menuItems.push(this.supplierMenuItem);
            if (displayArticle) this.menuItems.push(this.articleMenuItem);
            if (displayComponent) this.menuItems.push(this.componentMenuItem);
            if (displayFoodRequirement)
              this.menuItems.push(this.foodRequirementMenuItem);
            if (displayProduction) this.menuItems.push(this.productionMenuItem);
            if (displayInventory) this.menuItems.push(this.inventoryMenuItem);
            if (displaySettings) this.menuItems.push(this.settingsMenuItem);
            this.menuItems.push(this.informationMenuItem);
          }
        )
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
