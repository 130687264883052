import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceTypeIcon',
})
export class PriceTypeIconPipe implements PipeTransform {
  assetURL = 'assets/icons/price-tags';

  transform(value: number): string {
    switch (value) {
      case 2:
        return `${this.assetURL}/icon_pp.png`;
      case 3:
        return `${this.assetURL}/icon_kp.png`;
      default:
        return '';
    }
  }
}
