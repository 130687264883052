import { Pipe, PipeTransform } from '@angular/core';
import { InventoryFillTypeDto } from '../../storage-location/domain/models/inventory-fill-type-dto';

@Pipe({
  name: 'inventoryFillTypeDescription',
})
export class InventoryFillTypeDescriptionPipe implements PipeTransform {
  transform(value: number, types: InventoryFillTypeDto[] | null): string {
    return types?.find((type) => type.number === value)?.description ?? '';
  }
}
