import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '../services';

@Directive({
  selector: '[mmuBackButton]',
})
export class BackButtonDirective {
  @Input() url!: string;
  constructor(private navigationService: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    this.navigationService.navigateBack(false, this.url);
  }
}
