import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'mmu-forbidden-page',
  templateUrl: './forbidden.page.html',
  styleUrls: ['./forbidden.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenPageComponent {
  constructor(private _location: Location) {}

  previousPage() {
    this._location.back();
  }
}
