<div class="menu-card">
  <div class="menu-card-title-wrapper">
    <span
      class="menu-card-title"
      [ngClass]="disabled ? 'inactive' : ''"
      (click)="onClickTitle()"
      >{{ title }}</span
    >
    <ng-container *ngIf="ordersTotalCount > 0">
      <div
        style="justify-self: center"
        [ngClass]="disabled ? 'inactive' : ''"
        class="menu-card-orders-wrapper"
        pTooltip="An Lieferant übergebene Menge"
        tooltipPosition="top"
        (click)="onClickTotalOrders()"
      >
        <i></i>
        <span>
          {{ ordersTotalCount }}
        </span>
      </div>
    </ng-container>
    <mmu-menu *ngIf="menuItems.length > 0"></mmu-menu>
  </div>

  <div class="menu-card-body">
    <div
      class="menu-card-item-wrapper"
      *ngFor="let entry of entries"
      (click)="onClickMenuCardSelected(entry)"
    >
      <span
        class="menu-card-item-label"
        [ngClass]="disabled ? 'inactive' : ''"
        pTooltip="{{ entry.label }}"
        tooltipPosition="top"
        [life]="1500"
        >{{ entry.label }}</span
      >
      <div
        class="menu-card-action-wrapper"
        *ngIf="entry.actions.length !== 0 && !disabled"
      >
        <i
          class="menu-card-action"
          *ngFor="let action of entry.actions"
          (click)="onClickMenuCardItemAction(action)"
          [ngClass]="action.iconClass"
        >
        </i>
      </div>
    </div>
  </div>

  <div>
    <ng-content select="mmu-menuplan-tg-order-container"></ng-content>
  </div>
</div>
