import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MmInstanceHttpError } from '../models/mm-instance-http-error';
import { DialogsService } from '../services/dialogs.service';

@Injectable()
export class MmHttpErrorInterceptor implements HttpInterceptor {
  constructor(private dialogService: DialogsService) {}
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<MmInstanceHttpError | HttpErrorResponse>> {
    return next.handle(req).pipe(
      catchError((error) => {
        let mmInstanceError!: MmInstanceHttpError;
        if (error?.error?.instance) {
          mmInstanceError = {
            message: error?.error?.detail,
            statusCode: error?.status,
            instance: error?.error?.instance,
          };
        }
        if (error?.status === 500 || error?.status === 502) {
          this.dialogService.showError({
            message: 'Fehler ' + error?.status + '. ',
            title: 'Fehler ' + error?.status,
          });
        }
        return mmInstanceError
          ? throwError(mmInstanceError)
          : throwError(error);
      })
    );
  }
}
