<p-calendar
  #weekpicker
  placeholder="Kalenderwoche"
  [(ngModel)]="weekPickerDates"
  [minDate]="weekPickerMinDate"
  [maxDate]="weekPickerMaxDate"
  [showIcon]="true"
  [firstDayOfWeek]="1"
  [numberOfMonths]="2"
  [showWeek]="true"
  [showOtherMonths]="false"
  (onSelect)="selectDate($event)"
  dateFormat="dd.mm.yy"
  class="only-icon"
  selectionMode="range"
  appendTo="body"
  [style]="{ 'min-width': '235px' }"
  [readonlyInput]="true"
>
</p-calendar>
