import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as IdleActions from '../actions/idle.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import * as SettingsActions from '../actions/settings.actions';
import { SettingsService } from '../../services/settings.service';
import * as KitchenActions from '../actions/kitchen.actions';
import { IdleService } from '../../services';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private settingsService: SettingsService,
    private idleStore: Store<IdleService>
  ) {}

  loadSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SettingsActions.loadSettings,
        KitchenActions.getMyKitchensSuccess,
        KitchenActions.switchKitchenSuccess
      ),
      exhaustMap(() =>
        this.settingsService.getSettings().pipe(
          map((settingsResponse) => {
            const timespan = settingsResponse?.timeSpanInSeconds ?? 0;
            if (settingsResponse && timespan > 0) {
              this.idleStore.dispatch(
                IdleActions.idleStartWatching({
                  payload: {
                    idle: settingsResponse.timeSpanInSeconds ?? 0,
                  },
                })
              );
            }

            return SettingsActions.loadSettingsSuccess({
              payload: { settings: settingsResponse },
            });
          }),
          catchError((error) =>
            of(SettingsActions.loadSettingsFail({ payload: { error } }))
          )
        )
      )
    )
  );
}
