import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'column',
})
export class ColumnPipe implements PipeTransform {
  transform(value: unknown[], args: number[] | null): unknown[] {
    if (args && args.length > 0) {
      const sortedArgs = [...args].sort((a, b) => b - a);

      sortedArgs.forEach((index: number) => value.splice(index, 1));

      return value;
    }
    return value;
  }
}
