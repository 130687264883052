import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CopiedDietModel,
  MenuPlanEntryDto,
  MenuplanReportData,
  HttpErrorDto,
  MenuplanDietOrder,
  OrderData,
  MenuPlanDeleteQuery,
  MenuPlanDeletionTypeEnum,
} from '../models';

export const getMenuPlanEntriesForWholeWheek = createAction(
  '[MenuplanEntries] get menuplan entries for whole week',
  props<{ payload: { date: string } }>()
);
export const getMenuPlanEntriesForWholeWheekSuccess = createAction(
  '[MenuplanEntries] get menuplan entries for whole week success',
  props<{ payload: { menuplanEntries: MenuPlanEntryDto[] } }>()
);
export const getMenuPlanEntriesForWholeWheekFailed = createAction(
  '[MenuplanEntries] get menuplan entries for whole week failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const copyDiet = createAction(
  '[MenuplanEntries] copy diet ',
  props<{
    payload: {
      mealNumber: number;
      day: string;
      dietNumber: number;
    };
  }>()
);
export const copyDietSuccess = createAction(
  '[MenuplanEntries] copy diet success'
);

export const pasteDiet = createAction(
  '[MenuplanEntries] paste diet ',
  props<{
    payload: {
      mealNumber: number;
      day: string;
      dietNumber: number;
    };
  }>()
);
export const pasteSuccess = createAction(
  '[MenuplanEntries] paste diet success'
);

export const copyDay = createAction(
  '[MenuplanEntries] copy day ',
  props<{
    payload: {
      mealNumber: number;
      day: string;
    };
  }>()
);
export const pasteDay = createAction(
  '[MenuplanEntries] paste day ',
  props<{
    payload: {
      mealNumber: number;
      day: string;
    };
  }>()
);

export const copyWeek = createAction(
  '[MenuplanEntries] copy week ',
  props<{
    payload: {
      mealNumber: number;
      day: string;
    };
  }>()
);
export const pasteWeek = createAction(
  '[MenuplanEntries] paste week ',
  props<{
    payload: {
      mealNumber: number;
      day: string;
    };
  }>()
);

export const copyDietOnDb = createAction(
  '[MenuplanEntries] copy diet on db',
  props<{
    payload: {
      sourceDiet: CopiedDietModel;
      targetDiet: CopiedDietModel;
    };
  }>()
);
export const copyDietOnDbSuccess = createAction(
  '[MenuplanEntries] copy diet on db success',
  props<{
    payload: {
      parameters: {
        date: string | undefined;
        mealNumber: number | undefined;
        dietNumber: number | undefined;
      };
      menuplanEntries: MenuPlanEntryDto[];
    };
  }>()
);
export const copyDietOnDbFailed = createAction(
  '[MenuplanEntries] copy diet on db failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const copyDayOnDb = createAction(
  '[MenuplanEntries] copy day on db',
  props<{
    payload: {
      sourceDay: CopiedDietModel;
      targetDay: CopiedDietModel;
    };
  }>()
);
export const copyDayOnDbSuccess = createAction(
  '[MenuplanEntries] copy day on db db success',
  props<{ payload: { menuplanEntries: MenuPlanEntryDto[] } }>()
);
export const copyDayOnDbFailed = createAction(
  '[MenuplanEntries] copy day on db db failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const copyWeekOnDb = createAction(
  '[MenuplanEntries] copy week on db',
  props<{
    payload: {
      sourceWeek: CopiedDietModel;
      targetWeek: CopiedDietModel;
    };
  }>()
);
export const copyWeekOnDbSuccess = createAction(
  '[MenuplanEntries] copy week on db success',
  props<{ payload: { menuplanEntries: MenuPlanEntryDto[] } }>()
);
export const copyWeekOnDbFailed = createAction(
  '[MenuplanEntries] copy week on db failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const getMenuPlanEntriesForADiet = createAction(
  '[MenuplanEntries] get menuplan entries for a diet',
  props<{ payload: { date: string; mealNumber: number; dietNumber: number } }>()
);
export const getMenuPlanEntriesForADietSuccess = createAction(
  '[MenuplanEntries] get menuplan entries for a diet success',
  props<{
    payload: {
      parameters: {
        date: string | undefined;
        mealNumber: number | undefined;
        dietNumber: number | undefined;
      };
      menuplanEntries: MenuPlanEntryDto[];
    };
  }>()
);
export const getMenuPlanEntriesForADietFailed = createAction(
  '[MenuplanEntries] get menuplan entries for a diet failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const getMenuPlanEntriesForADay = createAction(
  '[MenuplanEntries] get menuplan entries for a day',
  props<{ payload: { date: string; mealNumber: number } }>()
);
export const getMenuPlanEntriesForADaySuccess = createAction(
  '[MenuplanEntries] get menuplan entries for a day success',
  props<{ payload: { menuplanEntries: MenuPlanEntryDto[]; type?: number } }>()
);
export const getMenuPlanEntriesForADayFailed = createAction(
  '[MenuplanEntries] get menuplan entries for a day failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const getMenuPlanEntriesForAWeek = createAction(
  '[MenuplanEntries] get menuplan entries for a week',
  props<{ payload: { date: string; mealNumber: number } }>()
);
export const getMenuPlanEntriesForAWeekSuccess = createAction(
  '[MenuplanEntries] get menuplan entries for a week success',
  props<{ payload: { menuplanEntries: MenuPlanEntryDto[] } }>()
);
export const getMenuPlanEntriesForAWeekFailed = createAction(
  '[MenuplanEntries] get menuplan entries for a week failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const getMenuplanReport = createAction(
  '[MenuPlan] Get menuplan report',
  props<{ payload: { date: string } }>()
);

export const getMenuplanReportSuccess = createAction(
  '[MenuPlan] Get menuplan report success',
  props<{ payload: { reportData: MenuplanReportData } }>()
);

export const getMenuplanReportFailed = createAction(
  '[MenuPlan] Get menuplan report failed',
  props<{ payload: { error: HttpErrorDto } }>()
);

export const clearMenuplanReportData = createAction(
  '[MenuPlan] clear menuplan report data'
);

export const orderabilityMenuplan = createAction(
  '[MenuPlan] post menuplan orderability data',
  props<{ payload: { startDate: string; endDate: string } }>()
);

export const orderabilityMenuplanSuccess = createAction(
  '[MenuPlan] orderability menuplan success',
  props<{ payload: { success: boolean } }>()
);

export const orderabilityMenuplanFailed = createAction(
  '[MenuPlan] orderability menuplan failed',
  props<{ payload: { error: HttpErrorDto } }>()
);

export const clearMenuplanOrderabilityData = createAction(
  '[MenuPlan] clear menuplan orderability data'
);

export const changeMenuplanOrder = createAction(
  '[MenuPlan] change menuplan order',
  props<{ payload: { dietOrder: MenuplanDietOrder[] } }>()
);

export const orderMenuplan = createAction(
  '[MenuPlan] order menuplan',
  props<{
    payload: {
      collectiveOrderNumber: number;
      mealNumber: number;
      order: MenuplanDietOrder[];
    };
  }>()
);
export const orderMenuplanSuccess = createAction(
  '[MenuPlan] order menuplan success',
  props<{ payload: { orderData: OrderData } }>()
);

export const orderMenuplanFailed = createAction(
  '[MenuPlan] order menuplan failed',
  props<{ payload: { error: HttpErrorDto } }>()
);

export const removeMenuplanEntry = createAction(
  '[MenuPlan] remove entry',
  props<{ payload: { entry: MenuPlanEntryDto } }>()
);

export const removeMenuplanEntrySuccess = createAction(
  '[MenuPlan] remove entry success',
  props<{ payload: { entry: MenuPlanEntryDto } }>()
);

export const removeMenuplanEntryFailed = createAction(
  '[MenuPlan] remove entry failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const removeMenuPlanEntriesByQuery = createAction(
  '[MenuPlan] remove menuplan entries by query',
  props<{
    payload: {
      type: MenuPlanDeletionTypeEnum;
      query: MenuPlanDeleteQuery;
      mealNumber: number;
    };
  }>()
);

export const removeMenuPlanEntriesByQuerySuccess = createAction(
  '[MenuPlan] remove menuplan entries by query success',
  props<{ payload: { amountDeletedRecipes: number } }>()
);

export const removeMenuPlanEntriesByQueryFailed = createAction(
  '[MenuPlan] remove menuplan entries by query failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const addMenuplanEntry = createAction(
  '[MenuPlan] add menuplan entry',
  props<{ payload: { entry: MenuPlanEntryDto } }>()
);

export const addMenuplanEntrySuccess = createAction(
  '[MenuPlan] add menuplan entry success',
  props<{ payload: { entry: MenuPlanEntryDto } }>()
);

export const addMenuplanEntryFailed = createAction(
  '[MenuPlan] add menuplan entry failed',
  props<{ payload: { error: HttpErrorResponse } }>()
);

export const clearMenuplanState = createAction(
  '[MenuPlan] clear menuplan state'
);
export const clearMenuplanSelectedDate = createAction(
  '[MenuPlan] clear menuplan selected date'
);
