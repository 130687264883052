import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';

export const emptyValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const c = control;
  return c?.value?.name?.length === 1 || Object.keys(c.value)?.length === 0
    ? { empty: true }
    : null;
};

@Directive({
  selector: '[mmuEmptyValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmptyValidatorDirective,
      multi: true,
    },
  ],
})
export class EmptyValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return emptyValidator(control);
  }
}
