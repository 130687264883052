export interface ProfileDataDto {
  userName: string;
  loginName: string;
  serverUtcTime: Date;
  sanalogicUserNumber?: number;
  kitchenName?: string;
  mealAtendeeName?: string;
  emailAddress?: string;
  serviceVersion?: string;
  clientDate?: Date;
}
