import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { KitchenDto } from '../../models/kitchen-dto';
import { LoginStatusType } from '@sanalogic/ui-core/login';
import { LoaderService } from '../../services/loader.service';
import { EnvironmentDto } from '../../models';

@Component({
  selector: 'mmu-kitchen-selection-view',
  templateUrl: './kitchen-selection-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KitchenSelectionViewComponent {
  @Input() kitchens!: KitchenDto[];
  @Input()
  get switchKitchenStatus(): number | null {
    return this._switchKitchenStatus;
  }
  set switchKitchenStatus(number: number | null) {
    if (number) {
      this._switchKitchenStatus = number;
      switch (number) {
        case LoginStatusType.DeviceNotAllowed:
          this.switchKitchenStatusErrorMessage =
            'Gerät wird nicht unterstützt.';
          break;
        case LoginStatusType.LdapUserNotInSanalogic:
          this.switchKitchenStatusErrorMessage =
            'Benutzername oder Passwort nicht korrekt.';
          break;
        case LoginStatusType.TooManyLoginAttempts:
          this.switchKitchenStatusErrorMessage =
            'Sie haben mehrmals versucht, sich mit fehlerhaften Eingaben anzumelden. ' +
            'Bitte Versuchen Sie es später erneut.';
          break;
        case LoginStatusType.WrongCredentials:
          this.switchKitchenStatusErrorMessage =
            'Benutzername oder Passwort nicht korrekt.';
          break;
        case LoginStatusType.InvalidKitchen: {
          this.switchKitchenStatusErrorMessage = 'Ungültiger Kunde.';
          break;
        }
      }
    }
  }
  private _switchKitchenStatus = 0;
  @Input() environment!: EnvironmentDto;

  @Output() selectKitchen = new EventEmitter<KitchenDto>();

  selectedKitchen!: KitchenDto;
  switchKitchenStatusErrorMessage!: string;

  constructor(public loaderService: LoaderService) {}

  trackByIndex(i: number): number {
    return i;
  }

  switchToSelectedKitchen(): void {
    this.switchKitchenStatusErrorMessage = '';
    this.selectKitchen.emit(this.selectedKitchen);
  }
}
