import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portionSize',
})
export class PortionSizePipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case PortionSize.SEN:
        return '=';
      case PortionSize.KLEIN:
        return '-';
      case PortionSize.NORM:
        return '';
      case PortionSize.GROSS:
        return '+';
      case PortionSize.DOPP:
        return '*';
    }
    return '';
  }
}

export enum PortionSize {
  SEN = 1,
  KLEIN,
  NORM,
  GROSS,
  DOPP,
}
